export type CmsCollectionType = {
	id: string;
	organizationId: string;
	dataSource: 'http_api';
	name: string;
	fields: CmsCollectionFieldItem[];
};

export type CmsCollectionFieldItem = {
	id: string;
	key: string;
	collectionId: string;
	name: string;
	type: CmsFieldType;
	updatedAt: string;
	createdAt: string;
};

export enum CmsFieldType {
	Text = 'text', // consider to add Link settings to make entire text as a link
	TextMultiline = 'textMultiline', // text..
	Number = 'number', // text..
	Image = 'image', // text..
	Boolean = 'boolean', // ??
	Date = 'date', // ??
	URL = 'url', // start with an image and button props
	// imageArray = 'imageArray', // image array of urls to repeat images
	// videoArray = 'videoArray', // image array of urls to repeat images
}

export type CmsCollectionDataItem = {
	id: string;
	position: number;
	created_at: string;
	updated_at: string;
	// custom user fields. see CmsCollectionFieldItem['key']
	[field: string]: CmsCollectionDataValue;
};

export type CmsCollectionDataValue = string | number | boolean | undefined | null;

export type CmsItemsRecord = { [itemId: string]: CmsCollectionDataItem };

export type CmsModel = {
	collections: CmsCollectionType[] | null;
	items: { [collectionId: string]: CmsItemsRecord | null };
};

export enum CollectionPaginationType {
	none = 'none',
	buttons = 'buttons',
	numbers = 'numbers',
}

export type CollectionPaginationTriggersProp = {
	// component id to trigger pagination
	prev: string;
	// component id to trigger pagination
	next: string;
	// TODO: add "first" and "last" triggers
	// first: string;
	// last: string;
};

export enum CmsFilterCondition {
	Equal = 'equal',
	StartsWith = 'startsWith',
	EndsWith = 'endsWith',
	Contains = 'contains',
}

/**
 * @param field       - collection field name to filter collection by, e.g. 'title'
 * @param componentId - component id, that is bound to the repeatable one to filter by
 * @param condition   - condition to filter by
 * @param value       - value to filter by
 */
export type CmsFilter<T = string> =
	| {
			type: 'search';
			field: string;
			componentId: string;
			condition: CmsFilterCondition;
	  }
	| {
			type: 'filter';
			field: string;
			componentId: string;
			condition: CmsFilterCondition;
			value: T;
	  }
	| {
			type: 'default';
			field: string;
			condition: CmsFilterCondition;
			value: T;
	  };

// AND - return ones that match all conditions
// OR - return ones that match any condition
type LogicalOperator = 'AND' | 'OR';
type ComparisonOperator = '=' | '!=' | '>' | '>=' | '<' | '<=';
type FilterField = keyof CmsCollectionDataItem;
type FilterValue = string | number | string[] | number[];

interface BaseFilter {
	field: FilterField;
	operator: ComparisonOperator;
	value: FilterValue;
}

interface LogicalFilter {
	operator: LogicalOperator;
	conditions: Filter[];
}

interface FindFilter {
	// FIND - return ones that match the condition
	type: 'FIND';
	field: FilterField;
	value: FilterValue;
}

type Filter = BaseFilter | LogicalFilter | FindFilter;

export interface ListCollectionItemsInput {
	collectionId: string;
	page?: number;
	pageSize?: number;
	orderBy?: keyof CmsCollectionDataItem;
	orderDir?: 'asc' | 'desc';
	filter?: Filter;
}
