import React, { useState } from 'react';

import { MODAL } from 'admin/constants/common';
import { useDebouncedState } from 'admin/hooks';

import t from 'utils/translate';
import { getSourceType } from 'src/common/utils/assets';

import { STORY_TYPE } from 'common/constants';
import { useAdminSelector } from 'admin/reducers';
import { selectEditableStoryType } from 'admin/reducers/story-editor/selectors';
import Button from 'admin/components/common/Button';
import { Modal } from 'admin/components/common/Modal';
import { Column } from 'admin/components/common/Grid';
import SettingsModalLayout from 'admin/components/common/SettingsModalLayout';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { InspectorContextType } from '../../InspectorContext';

import Search from './Search';
import UploadView from './UploadView';
import GalleryView from './GalleryView';
import FromURLView from './FromURLView';

import type { ViewProps, CurrentValue } from './types';

import css from './MediaGalleryModal.scss';

export type MediaGalleryModalData = {
	onUpdate: NonNullable<ReturnType<InspectorContextType['getFieldListeners']>['onUpload']>;
	name: string;
};

type ModalProps = ModalManagerProvidedProps<MODAL.MEDIA_GALLERY>;

enum VIEW {
	GALLERY,
	UPLOAD,
	FROM_URL,
	VIDEO_PLATFORM_URL,
}

const viewMap = {
	[VIEW.GALLERY]: GalleryView,
	[VIEW.UPLOAD]: UploadView,
	[VIEW.FROM_URL]: FromURLView,
	[VIEW.VIDEO_PLATFORM_URL]: FromURLView,
};

const MediaGalleryModalContent: React.FC<Omit<ModalProps, 'open'>> = props => {
	const storyType = useAdminSelector(selectEditableStoryType);
	const [search, setSearch] = useDebouncedState('', 400);
	const [value, setValue] = useState<CurrentValue>(null);
	const { data } = props;

	const [view, setView] = useState<VIEW>(storyType === STORY_TYPE.WIDGET ? VIEW.FROM_URL : VIEW.GALLERY);

	const menu = React.useMemo(() => {
		return [
			{
				label: 'Gallery',
				isActive: VIEW.GALLERY === view,
				disabled: false,
				isVisible: storyType !== STORY_TYPE.WIDGET,
				onClick: () => setView(VIEW.GALLERY),
			},
			{
				label: 'Upload',
				isActive: VIEW.UPLOAD === view,
				disabled: false,
				isVisible: storyType !== STORY_TYPE.WIDGET,
				onClick: () => setView(VIEW.UPLOAD),
			},
			{
				label: 'Play From URL',
				isActive: VIEW.FROM_URL === view,
				disabled: false,
				isVisible: true,
				onClick: () => setView(VIEW.FROM_URL),
			},
			{
				label: 'Youtube/Vimeo',
				isActive: VIEW.VIDEO_PLATFORM_URL === view,
				disabled: false,
				isVisible: true,
				onClick: () => setView(VIEW.VIDEO_PLATFORM_URL),
			},
		].filter(item => item.isVisible);
	}, [view, storyType]);

	const contentClassNameByView = React.useMemo(() => {
		if (view === VIEW.GALLERY) return css.content;
		if (view === VIEW.UPLOAD) return css.uploadContent;
		return undefined;
	}, [view]);

	const isValueValid = React.useMemo(() => {
		const mediaFormat = getSourceType(value?.src);

		switch (view) {
			case VIEW.FROM_URL:
				return mediaFormat === 'video' || mediaFormat === 'hls';
			case VIEW.VIDEO_PLATFORM_URL:
				return mediaFormat === 'vimeo' || mediaFormat === 'youtube';
			default:
				return mediaFormat === 'hls';
		}
	}, [value, view]);

	const onSubmitBtnClick = () => {
		data.onUpdate(
			{ asset: value?.src ?? '', name: data.name },
			{ posterUrl: value?.poster, transcodingId: value?.transcodingId }
		);
		props.close();
	};

	const onUploadSuccess = () => {
		setView(VIEW.GALLERY);
	};

	const renderContent = () => {
		const Content = viewMap[view];
		const contentProps: ViewProps = {
			isValueValid,
			currentValue: value,
			search,
			setValue,
			...(view === VIEW.UPLOAD && { closeModal: props.close, onUploadSuccess }),
			...(view === VIEW.VIDEO_PLATFORM_URL ? { fromUrlType: 'videoPlatform' } : {}),
		};

		return (
			<>
				<Content {...contentProps} />

				{view !== VIEW.UPLOAD && (
					<div className={css.footer}>
						<Column justifyContent="flex-start">
							<Button
								type="button"
								view="primary"
								textSize="label"
								onClick={onSubmitBtnClick}
								disabled={!isValueValid}
								className={css.button}
							>
								{t('story.mediaGalleryModal.save')}
							</Button>
							<Button onClick={props.close} textSize="label" view="secondary-gray" className={css.button}>
								{t('story.mediaGalleryModal.discard')}
							</Button>
						</Column>
					</div>
				)}
			</>
		);
	};

	const onSearchInputChange = (inputValue: string) => {
		setSearch(inputValue);
	};

	const renderHeader = () => {
		return view === VIEW.GALLERY && <Search value={search} onSearch={onSearchInputChange} />;
	};

	return (
		<SettingsModalLayout
			contentClassName={contentClassNameByView}
			menu={menu}
			renderHeader={renderHeader}
			renderContent={renderContent}
		/>
	);
};

const MediaGalleryModal = ({ open, ...props }: ModalProps) => {
	return (
		<Modal
			maskColor="black"
			theme="dark"
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			transitionName=""
		>
			{/* modal will unmount its content on close, but won't destroy itself */}
			<MediaGalleryModalContent {...props} />
		</Modal>
	);
};

export default MediaGalleryModal;
