import * as Sentry from '@sentry/react';
import { IS_ADMIN } from 'utils/environment';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import css from './ErrorBoundary.scss';

const MAX_RELOADS = 3;
const RELOAD_DELAYS = [500, 1500, 2500]; // Delays in ms
const SESSION_KEY = 'error-reload-count';
const HIDE_ERROR_UI = !IS_ADMIN;
const CAN_RELOAD = !IS_ADMIN;

type State = {
	error: Error | null;
	info: ErrorInfo | null;
	eventId?: string;
};

type Props = {
	content?: () => ReactNode;
	children: ReactNode;
};

class ReloadTracker {
	getCount(): number {
		return parseInt(sessionStorage.getItem(SESSION_KEY) || '0', 10);
	}

	setCount(count: number): void {
		sessionStorage.setItem(SESSION_KEY, count.toString());
	}

	resetCount(): void {
		sessionStorage.removeItem(SESSION_KEY);
	}
}

const reloadTracker = new ReloadTracker();

class ErrorBoundary extends Component<Props, State> {
	state: State = { error: null, info: null };

	static getDerivedStateFromError(error: Error): State {
		return { error, info: null };
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (CAN_RELOAD && this.state.error && this.state.error !== prevState.error) {
			const reloadCount = reloadTracker.getCount();

			if (reloadCount < MAX_RELOADS) {
				reloadTracker.setCount(reloadCount + 1);
				const delay = RELOAD_DELAYS[reloadCount] || RELOAD_DELAYS.at(-1);

				setTimeout(() => {
					window.location.reload();
				}, delay);
			} else {
				// Max reload attempts reached, showing fallback UI
				this.logToService(this.state.error, this.state.info);
				// Reset reload count to allow user to retry manually
				reloadTracker.resetCount();
			}
		}
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		this.setState({ info });
	}

	logToService(error: Error, info: ErrorInfo | null) {
		Sentry.withScope(scope => {
			if (info) scope.setExtras({ componentStack: info.componentStack });
			const eventId = Sentry.captureException(error);
			if (eventId) this.setState({ eventId });
		});
	}

	render() {
		const { error, info, eventId } = this.state;

		if (!error) {
			return this.props.children;
		}

		if (HIDE_ERROR_UI) {
			return null;
		}

		return (
			<div className={css.root}>
				<div className={css.hero}>
					<h2 className={css.title}>
						Something went wrong...
						<span role="img" aria-labelledby="error">
							🙊🙈🙉
						</span>
					</h2>
					<button
						className={css.btn}
						type="button"
						onClick={() => {
							reloadTracker.resetCount();
							window.location.reload();
						}}
						data-text="reload page"
					>
						Reload page
					</button>
					{eventId && (
						<button
							type="button"
							data-text="report issue"
							className={`${css.btn} ${css.btnReport}`}
							onClick={() => Sentry.showReportDialog({ eventId })}
						>
							Report issue
						</button>
					)}
				</div>
				{info?.componentStack && (
					<details style={{ whiteSpace: 'pre-wrap' }}>
						<summary>Details - {error.toString()}</summary>
						{info.componentStack}
					</details>
				)}
				{this.props.content && this.props.content()}
			</div>
		);
	}
}

export default ErrorBoundary;
