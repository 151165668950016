import Pubnub from 'pubnub';
import { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useAdminSelector } from 'admin/reducers';
import { QUERY_KEYS } from 'admin/queries/constants';
import { selectActiveOrganizationId, selectUserInfo } from 'admin/reducers/user/selectors';

import PubnubFacade from 'common/utils/facades/pubnub-facade';
import { PaginatedData } from 'src/routes/admin/queries/types';
import { GetVideoResult, GetVideosResult } from 'src/types';
import produce from 'immer';
import { getVideoQueryFn, getVideoQueryKey } from 'src/routes/admin/queries/videos';

enum MessageType {
	transcodingUpdated = 'transcoding_updated',
	transcodingCreated = 'transcoding_created',
	transcodingDeleted = 'transcoding_deleted',
}

interface TranscodingUpdatedMessageEvent extends Pubnub.MessageEvent {
	message: {
		type: MessageType;
		data: {
			id: string;
			organizationId: string;
			traceId: string;
		};
		name: string;
	};
}

function isTranscodingProcessMessageEvent(msgEvent: Pubnub.MessageEvent): msgEvent is TranscodingUpdatedMessageEvent {
	const type = msgEvent?.message?.type;

	return type === MessageType.transcodingUpdated || type === MessageType.transcodingCreated;
}

function isDeleteVideoMessageEvent(msgEvent: Pubnub.MessageEvent): msgEvent is TranscodingUpdatedMessageEvent {
	return msgEvent?.message?.type === MessageType.transcodingDeleted;
}

const VideoGalleryObserver: FC = () => {
	const client = useQueryClient();
	const videoIdRef = useRef<string>('');
	const [pubnub, setPubnub] = useState<PubnubFacade>();
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { id: userId } = useAdminSelector(selectUserInfo);

	// initialize
	useEffect(() => {
		if (userId) {
			setPubnub(prev => {
				// end all open requests and close the PubNub instance.
				if (prev) prev.close();
				// initialize
				return new PubnubFacade({ uuid: userId });
			});
		}
	}, [userId]);

	useEffect(() => {
		const unsubscribe = client.getQueryCache().subscribe(async event => {
			if (!event) {
				return;
			}

			const isMatchKey =
				event.query.queryHash === JSON.stringify([QUERY_KEYS.VIDEO_ITEM, organizationId, videoIdRef.current]);

			if (event.type === 'updated' && isMatchKey && event.action.type === 'success') {
				const result = event.query.state.data as GetVideoResult;
				const video = result.transcoding;
				const paginateKey = [QUERY_KEYS.PAGINATE_VIDEOS, organizationId];

				// update item in the pagination data array
				client.setQueryData<PaginatedData<GetVideosResult>>(paginateKey, pData => {
					if (!pData) {
						// fixme: prepend added video to the first page instead of invalidating the query
						// details: on dev/prod, but no at localhost, pData is undefined when video is added
						// as a temporary fix, invalidate the query to get the latest data
						client.invalidateQueries({ queryKey: paginateKey });
						return undefined;
					}

					const newPages = produce(pData.pages, draft => {
						let videoIndex = -1;
						let pageIndex = -1;

						draft.forEach((page, index) => {
							const targetIndex = page.transcodings.findIndex(t => t.id === video.id);

							if (targetIndex >= 0) {
								videoIndex = targetIndex;
								pageIndex = index;
							}
						});

						if (videoIndex >= 0) {
							draft[pageIndex].transcodings[videoIndex] = video;
						} else {
							draft[0].transcodings.unshift(video);
						}
					});

					return {
						...pData,
						pages: newPages,
					};
				});
			}
		});

		return () => unsubscribe();
	}, [client, organizationId]);

	useEffect(() => {
		const CHANNEL_NAME = `${organizationId}.videos`;

		if (pubnub) {
			pubnub.subscribe(CHANNEL_NAME);
			pubnub.addListeners({
				async message(messageEvent: Pubnub.MessageEvent) {
					const videoId = messageEvent?.message?.data?.id;

					if (isTranscodingProcessMessageEvent(messageEvent)) {
						const qKey = [QUERY_KEYS.VIDEO_ITEM, organizationId, videoId];
						videoIdRef.current = videoId;

						const qData = client.getQueryData(qKey);

						if (qData) {
							client.invalidateQueries({
								queryKey: qKey,
								refetchType: 'all',
							});
						} else {
							await client.fetchQuery({
								queryFn: () => getVideoQueryFn({ organizationId, videoId }),
								queryKey: getVideoQueryKey({ organizationId, videoId }),
							});
						}
					}

					if (isDeleteVideoMessageEvent(messageEvent)) {
						const qKey = [QUERY_KEYS.VIDEO_ITEM, organizationId, videoId];
						const paginateKey = [QUERY_KEYS.PAGINATE_VIDEOS, organizationId];

						// Remove video data from cache
						client.removeQueries({ queryKey: qKey });

						// Update list of video, by removing the current one
						client.setQueryData<PaginatedData<GetVideosResult>>(paginateKey, pData => {
							if (!pData) {
								// fixme: update the pagination data array instead of invalidating the query
								client.invalidateQueries({ queryKey: paginateKey });
								return undefined;
							}

							const newPages = produce(pData.pages, draft => {
								draft.forEach(draftPage => {
									draftPage.transcodings = draftPage.transcodings.filter(t => t.id !== videoId);
								});
							});

							return { ...pData, pages: newPages };
						});
					}
				},
			});
		}

		return () => {
			pubnub?.removeListeners();
			pubnub?.unsubscribe(CHANNEL_NAME);
		};
	}, [pubnub, organizationId, client]);

	return null;
};

export default VideoGalleryObserver;
