import React, { FC, useEffect, PropsWithChildren, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { clientLog } from 'common/utils/helpers';
import cms from 'common/utils/cms';
import { selectCardElements } from 'client/reducers/card/selectors';
import { selectEditorState } from 'client/reducers/editor/selectors';
import { IFRAME_ACTIONS, transmitTo } from 'common/utils/iframe-tunnel';
import { selectStorycardsDomain } from 'client/reducers/story/selectors';
import { TRAVERSE_TREE_TARGETS } from 'client/components/common/BuildingBlocks/utils/common';
import { traverseTree } from 'client/components/common/BuildingBlocks/utils/traverse-tree';
import { useResponsiveContext } from 'client/components/common/ResponsiveProvider/Context';
import { BBModel, StorySymbols } from 'types/story';
import { ClientReducerState, useClientSelector, useClientDispatch } from 'client/reducers';
import {
	ITraverseTreeOptions,
	ITraverseTreeOptionsListeners,
} from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import { CmsReferencesManager } from 'utils/cms/cms-references-manager';
import {
	useListCollectionItems,
	listCollectionItemsQueryFn,
	listCollectionItemsQueryKey,
} from 'client/queries/cms/list-collection-items';
import { useQueries } from '@tanstack/react-query';
import type { ListCollectionItemsInput } from 'types/cms';

const log = clientLog.extend('TreeRenderer');

const mapStateToProps = (state: ClientReducerState) => ({
	editorMode: state.editor.editorMode,
	state: selectEditorState(state),
	cardElements: selectCardElements(state)!,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
	treeOptions:
		| { isCardTree: ITraverseTreeOptions['isCardTree']; target: ITraverseTreeOptions['target'] }
		| ({ isCardTree: ITraverseTreeOptions['isCardTree']; target: 'editor' } & ITraverseTreeOptionsListeners);
	elements: BBModel[];
	symbols?: StorySymbols;
};

type ExtraProps = {
	state?: Props['state'];
};

const CmsItemsFetcher: FC<{
	symbols: StorySymbols | undefined;
	elements: BBModel[];
	renderChildren: () => ReactNode;
}> = props => {
	const dispatch = useClientDispatch();

	// Fetch collections and items for card
	const cmsReferencesManager = new CmsReferencesManager();
	cmsReferencesManager.collectComponentReferences(props.elements, props.symbols);
	const cmsReferences = cmsReferencesManager.getReferences();
	console.info('=== CmsItemsFetcher:refs ===', cmsReferences);

	const queries = useQueries({
		queries: Object.entries(cmsReferences).map(([collectionId, items]) => {
			const queryParams: ListCollectionItemsInput = {
				collectionId,
				filter: {
					operator: 'OR',
					conditions: items.map(itemId => {
						if (itemId === cms.DATA_ANY) {
							return { field: 'position', operator: '=', value: 0 };
						}
						return { field: 'id', operator: '=', value: itemId };
					}),
				},
			};

			return {
				queryKey: listCollectionItemsQueryKey(queryParams),
				queryFn: () => listCollectionItemsQueryFn({ dispatch, ...queryParams }),
			};
		}),
	});

	console.info(
		'=== CmsItemsFetcher:queries ===',
		queries,
		queries.some(query => query.isLoading)
	);

	return queries.some(query => query.isLoading) ? null : props.renderChildren();
};

const TreeRenderer: FC<Props> = props => {
	const isEditor = props.treeOptions.target === TRAVERSE_TREE_TARGETS.EDITOR;
	const { state, elements, treeOptions, cardElements, symbols, editorMode } = props;
	const { mediaQuery, currentMediaQuery } = useResponsiveContext();
	const storycardsDomain = useClientSelector(selectStorycardsDomain);

	const tree = props.treeOptions.isCardTree ? 'card' : 'story';
	useEffect(() => {
		// if (isEditor) return;
		// console.info('=== TreeRenderer ===', { tree, elements });
	}, [tree, isEditor, elements]);

	log(`%c${treeOptions.isCardTree ? 'cardElements' : 'storyElements'}`, 'font-weight: bold');

	if (!mediaQuery || !currentMediaQuery) {
		return null;
	}

	// 1. собери референсы
	// 2. если они есть, то нужно сделать запросы на сервер
	// 3. если запросы идут, то показывать лоадер

	// Fetch collections and items for card
	// const cmsReferencesManager = new CmsReferencesManager();
	// cmsReferencesManager.collectComponentReferences(card.elements, storyFacade.symbols);
	// cmsReferencesManager.collectComponentReferences(storyFacade.elements, storyFacade.symbols);
	// const cmsReferences = cmsReferencesManager.getReferences();

	const render = () => {
		console.info('=== TreeRenderer.render() ===', treeOptions.isCardTree ? 'card' : 'story');
		try {
			const extraProps: ExtraProps = isEditor ? { state } : {};
			const options = {
				...treeOptions,
				...extraProps,
				mediaQuery,
				currentMediaQuery,
				cardElements,
				symbols,
				editorMode,
				storycardsDomain,
			};
			return <>{traverseTree({ tree: elements, options })}</>;
		} catch (e) {
			console.error(e);

			if (isEditor)
				transmitTo({
					id: 'TreeRenderer',
					target: 'admin',
					action: IFRAME_ACTIONS.STORY_RENDER_FAILED,
					payload: { error: e instanceof Error ? e.message : 'Failed to render story' },
				});

			return null;
		}
	};

	// At the CardEditor cms items data comes from admin, but at the preview or published story the items data
	// is fetched by the client app itself
	return isEditor ? render() : <CmsItemsFetcher symbols={symbols} elements={elements} renderChildren={render} />;
};

function areEqual(prevProps: Props, nextProps: Props) {
	const isEditorModeEqual = prevProps.editorMode === nextProps.editorMode;
	const isElementsEqual = prevProps.elements === nextProps.elements;
	const isSymbolsEqual = prevProps.symbols === nextProps.symbols;
	const isStateEqual =
		Object.keys(nextProps.state.source).some(key => nextProps.state.source[key] === prevProps.state.source[key]) &&
		prevProps.state.state === nextProps.state.state;

	const isEqual = isEditorModeEqual && isStateEqual && isElementsEqual && isSymbolsEqual;

	if (!nextProps.treeOptions.isCardTree) {
		const isCardElementsEqual = prevProps.cardElements === nextProps.cardElements;
		return isEqual && isCardElementsEqual;
	}

	return isEqual;
}

export default connector(React.memo(TreeRenderer, areEqual));
