import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import t from 'utils/translate';
import { IVideo } from 'src/types';

import { formatTime } from 'admin/utils';
import { useIntersectionObserver } from 'admin/hooks';
import { usePaginateVideos } from 'admin/queries/videos';
import { VIDEO_DETAILS_PAGE } from 'admin/constants/routes';
import Text from 'admin/components/common/Text';

import { PARAMS } from 'src/common/constants';
import Logo from 'src/common/components/StoryCardsLogo';
import Loader from 'src/common/components/Loader';
import MediaPlayer from 'src/common/components/VideoPlayer';

import { ViewProps } from './types';

import css from './MediaGalleryModal.scss';

const GalleryView: React.FC<ViewProps> = ({ search, setValue, currentValue }) => {
	const { data, isFetching, hasNextPage, fetchNextPage } = usePaginateVideos({ term: search, withStats: false });
	const fetchTriggerRef = useRef<HTMLDivElement>(null);

	const onFetchTriggerIntersection = () => {
		if (!isFetching && hasNextPage) {
			fetchNextPage();
		}
	};

	const onVideoItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const { src, poster, transcodingId } = e.currentTarget.dataset;

		if (src) setValue?.({ src, poster, transcodingId });
	};

	useIntersectionObserver(fetchTriggerRef, onFetchTriggerIntersection);

	useEffect(() => {
		setValue?.(null);

		/**
		 * each view represents ability to select value by different way
		 * so when user open the view we need to reset selected value from
		 * the previous visited view
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderPlayBtnAndDuration = (isSelected: boolean, duration: number) => {
		if (!isSelected)
			return (
				<>
					<div className={css.playIcon} />
					<div className={css.duration}>
						<Text compact size={Text.size.footnote}>
							{formatTime(duration)}
						</Text>
					</div>
				</>
			);

		return null;
	};

	const renderVideoItem = (video: IVideo) => {
		const videoLink = video.playlists[0];
		const isSelected = currentValue?.src === videoLink;

		return (
			<div
				className={cn(css.videoItem, isSelected && css.active, !videoLink && css.disabled)}
				onClick={onVideoItemClick}
				data-src={videoLink}
				data-poster={video.poster}
				data-transcoding-id={video.id}
				key={video.id}
			>
				<div
					className={css.poster}
					style={video.poster ? { backgroundImage: `url('${video.poster}')` } : undefined}
				>
					{!video.poster && <Logo className={css.imgIcon} isMinimal />}
					{video.status !== 'COMPLETED' ? (
						<div className={css.badge}>
							<Text
								compact
								size={Text.size.footnote}
								text={
									video.status === 'TRANSCODING' ? `${video.status} ${video.progress}%` : video.status
								}
							/>
						</div>
					) : (
						renderPlayBtnAndDuration(isSelected, video.durationInMs)
					)}

					{isSelected && <MediaPlayer src={videoLink} autoplay muted loop={false} controls={false} />}
				</div>
				<div className={css.info}>
					<Link
						to={VIDEO_DETAILS_PAGE.replace(`:${PARAMS.VIDEO_ID}`, video.id)}
						className={css.infoLink}
						target="_blank"
					>
						<Text compact size={Text.size.footnote} tag="span">
							{video.title}
						</Text>
					</Link>
					<Text className={css.infoDate} compact size={Text.size.footnote} tag="p">
						{dayjs(video.createdAt).format('MMM DD YYYY')}
					</Text>
				</div>
			</div>
		);
	};

	return (
		<div className={css.gallery}>
			<div className={css.title}>
				<Text compact size={Text.size.subheading} weight={Text.weight.semibold} tag="h4">
					{t('story.mediaGalleryModal.gallery.title')}
				</Text>
			</div>
			<div className={css.videosList}>
				{data?.items.map(renderVideoItem)}

				{data?.items.length === 0 && search && (
					<Text compact size={Text.size.description} className={css.noResults}>
						{t('story.mediaGalleryModal.gallery.noResultsFound', { search })}
					</Text>
				)}

				{data?.items.length === 0 && !search && (
					<Text compact size={Text.size.description} className={css.noResults}>
						{t('story.mediaGalleryModal.gallery.emptyGallery')}
					</Text>
				)}

				<div ref={fetchTriggerRef} />
			</div>

			{isFetching && (
				<div className={css.loadingWrap}>
					<Loader size="small" bgColor="var(--ra-color-white-mid)" color="var(--ra-color-white-dark)" />
				</div>
			)}
		</div>
	);
};

export default GalleryView;
