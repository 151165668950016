import type { ErrorInfo } from 'react';
import ErrorBoundary from 'common/components/ErrorBoundary';
import AdminError from './AdminError';

class ErrorBoundaryAdmin extends ErrorBoundary {
	componentDidCatch(error: Error, info: ErrorInfo) {
		this.logToService(error, info);

		if (error instanceof AdminError && error.notification) {
			return;
		}

		this.setState({ info });
	}
}

export default ErrorBoundaryAdmin;
