import { noop } from 'lodash';
import React from 'react';
import { BBCommonProps } from 'types/story';

export type ComponentEvents = Record<string /* component ID */, ComponentEventsItem>;

type ComponentEventsItem = {
	onSwipe?: () => void;
	onAnswerClick?: (event: React.MouseEvent<HTMLElement, MouseEvent> | 'swipe') => void;
};

export type EventProviderFlowOptions = {
	isDirectId?: boolean;
	scrollTo?: string;
	reload?: boolean;
};

export type EventProviderContextT = {
	eventProvider: {
		events: {
			/**
			 * Navigate to another card by events defined at Flow screen in admin panel
			 *
			 * @param eventId id of an event in "card.events" (aka. "flow events") or id of certain card/step
			 * @params options {object}
			 * @param options.isDirectId determines that this is not a regular flow event, don't look for it,
			 *        just take an id and navigate to such card/step by id
			 * @param options.scrollTo id of the Element to scroll into view (see `btnBlockLink`)
			 * @param options.reload fully reload page after `history.push`
			 */
			flow: (eventId: string, options?: EventProviderFlowOptions) => void;
			/**
			 * List of component events, which are optional and can be defined by any component
			 * in order to be able to call its event handlers by other components
			 */
			component: ComponentEvents;
		};
		setComponentEvents: (componentId: string, value: ComponentEventsItem) => void;
		nextDefaultCardId: string;
	};
};

export const EventProviderContext = React.createContext<EventProviderContextT>({
	eventProvider: {
		events: {
			flow: noop,
			component: {},
		},
		setComponentEvents: noop,
		nextDefaultCardId: '',
	},
});

export const withEventProvider = (Component: React.ComponentType<any>) => {
	const WithEventProvider = (props: BBCommonProps) => (
		<EventProviderContext.Consumer>
			{contextProps => <Component {...{ ...props, ...contextProps }} />}
		</EventProviderContext.Consumer>
	);

	WithEventProvider.displayName = `WithEventProvider(${Component.displayName || Component.name || 'Component'})`;

	return WithEventProvider;
};
