/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise, no-plusplus, max-len, lines-between-class-members */

import { filter, get, values, map, reduce, invoke, findIndex, sortBy, forEach, isEmpty, find, merge } from 'lodash';
import SVG from 'svg.js';

import { EventEmitter } from 'utils/event-emitter';
import { ANIMATION_TRIGGER, ANIMATION_EFFECT_ID } from 'common/constants';
import { ANIMATION_HOC_SENDER_ID, ANIMATION_SETTINGS_SENDER_ID } from 'client/constants/common';

export type AnimationSettingsEvent = {
	sender: typeof ANIMATION_SETTINGS_SENDER_ID | typeof ANIMATION_HOC_SENDER_ID;
	type: string;
	trigger: string;
	elementId: string;
};

const lengthValues = {
	rem: 'rem',
	em: 'em',
	cm: 'cm',
	mm: 'mm',
	in: 'in',
	px: 'px',
	pt: 'pt',
	pc: 'pc',
	'%': '%',
};

const timeValues = {
	s: 's',
	ms: 'ms',
};

const fieldValues = {
	duration: {
		units: { ...timeValues },
		default: 's',
	},
	delay: {
		units: { ...timeValues },
		default: 's',
	},
	x: {
		units: { px: 'px' },
		default: 'px',
	},
	y: {
		units: { px: 'px' },
		default: 'px',
	},
	rotation: {
		units: { deg: 'deg' },
		default: 'deg',
	},
	scale: {
		units: {},
		default: '',
	},
	scaleX: {
		units: {},
		default: '',
	},
	scaleY: {
		units: {},
		default: '',
	},
	opacity: {
		units: {},
		default: '',
	},

	default: {
		units: { ...lengthValues, none: '' },
		nonUnits: ['auto', 'none', 'normal', 'initial', 'inherit'],
		default: '',
	},
};

/**
 * CSS/HTML/JS Units of Measure Parsing
 * Regular Expressions (RegEx / RegExp) Pattern
 * Test http://www.regexr.com/39424
 * Pattern /(auto|none)|([-+]?\d*\.?\d+)\s?(px|em|rem|%|vw|vh+)?/i
 */
const parseFieldValue = (
	fieldName: string,
	value: string
): { string?: string; number?: number; unit?: string; invalid?: boolean } | null => {
	if (value === undefined || value === '') {
		return null;
	}

	const { units, nonUnits } = get(fieldValues, fieldName, fieldValues.default);
	const nonUnitsGroup = filter(values(nonUnits), o => o !== '').join('|');
	const unitsGroup = filter(values(units), o => o !== '').join('|');

	if (nonUnitsGroup) {
		const valuePattern = `(${nonUnitsGroup})`;
		const valueRegex = new RegExp(valuePattern, 'i');
		const valueMatch = value.toString().match(valueRegex);

		if (valueMatch) {
			const [, v] = valueMatch;

			return { string: v, unit: '' };
		}
	}

	if (unitsGroup) {
		const unitPattern = `.*?(${unitsGroup}+)$`;
		const unitRegex = new RegExp(unitPattern, 'i');
		const unitMatch = value.toString().match(unitRegex);

		if (unitMatch) {
			const [, unit = ''] = unitMatch;
			const numberValue = +value.substring(0, value.length - unit.length);
			return numberValue ? { number: numberValue, unit } : { unit };
		}
	}

	return { string: value, invalid: Number.isNaN(parseFloat(value)) };
};

class TransformMatrix2D {
	public m: number[][];

	constructor(a = 1, b = 0, c = 0, d = 1, e = 0, f = 0) {
		this.m = [
			[a, c, e],
			[b, d, f],
			[0, 0, 1],
		];
	}

	multiply(matrix: TransformMatrix2D) {
		const tm = matrix.m;
		const result: number[][] = [];
		let sum: number;

		for (let i = 0; i < this.m.length; i += 1) {
			result[i] = [];
			for (let k = 0; k < tm[0].length; k += 1) {
				sum = 0;
				for (let j = 0; j < this.m[i].length; j += 1) {
					sum += tm[j][k] * this.m[i][j];
				}
				result[i].push(Math.round(sum * 1000) / 1000);
			}
		}

		this.m = result;
		return this;
	}

	toCSS() {
		const a = this.m[0][0];
		const b = this.m[1][0];
		const c = this.m[0][1];
		const d = this.m[1][1];
		const e = this.m[0][2];
		const f = this.m[1][2];

		return `matrix(${a}, ${b}, ${c}, ${d}, ${e}, ${f})`;
	}
}

/**
 * Implementation of setTimeout based on requestAnimationFrame callback,
 * all animation events are fired in sync with animation rendering
 */
type Callback = {
	id: number;
	targetFrame: number;
	targetTime: number;
	callback: Function;
};
class RafTicker {
	static DEFAULT_FPS = 60;

	private isActive: boolean;
	private currentFrame: number;
	private currentTime: number;
	private frameTime: number;
	private timeoutsCounter: number;
	private callbackStack: Callback[];

	constructor() {
		this.isActive = true;
		this.currentFrame = 0;
		this.currentTime = Date.now();
		this.frameTime = 1000 / RafTicker.DEFAULT_FPS;
		this.timeoutsCounter = 0;
		this.callbackStack = [];

		window.requestAnimationFrame(this.rafListener);
	}

	rafListener = () => {
		if (!this.isActive) return;

		let i = 0;

		while (i < this.callbackStack.length) {
			if (this.currentTime >= this.callbackStack[i].targetTime) {
				invoke(this.callbackStack[i], 'callback');
				this.callbackStack.splice(i, 1);
				i--;
			}
			i++;
		}

		// this.timeoutsCounter = 0;
		this.currentFrame += 1;
		this.currentTime = Date.now();
		window.requestAnimationFrame(this.rafListener);
	};

	setTimeout = (callback: Function, time = 0) => {
		const id = this.timeoutsCounter++;

		this.callbackStack.push({
			id,
			targetFrame: (this.currentFrame + time / this.frameTime) | 0,
			targetTime: this.currentTime + time,
			callback,
		});

		return id;
	};

	clearTimeout = (id: number) => {
		const index = findIndex(this.callbackStack, { id });

		if (index >= 0) {
			this.callbackStack.splice(index, 1);
		}
	};

	stop = () => {
		this.isActive = false;
	};

	play = () => {
		this.isActive = true;
	};
}

export const rafTicker = new RafTicker(); // single ticker object for export
const svgContainer = document.createElement('div');
const canvas = SVG(svgContainer) // svg helper to get points on curves (easing curves)
	.size('100%', '100%')
	.viewbox(0, 0, 1, 1);

/** easing types */
export const Ease = {
	POWER_0: 'power_0',
	POWER_IN_1: 'powerIn_1',
	POWER_OUT_1: 'powerOut_1',
	POWER_IN_OUT_1: 'powerInOut_1',
	BACK_IN: 'backIn',
	BACK_OUT: 'backOut',
	BACK_IN_OUT: 'backInOut',
	BOUNCE_IN: 'bounceIn',
	BOUNCE_OUT: 'bounceOut',
	BOUNCE_IN_OUT: 'bounceInOut',
};

/** corresponding easing type svg-curves, any svg-curve can be used for animation timing function */
export const EaseCurve = {
	[Ease.POWER_0]: `M0,0,C0,0,1,1,1,1`,

	[Ease.POWER_IN_1]: `M0,0,C0.532,0,0.924,0.862,1,1`,
	[Ease.POWER_OUT_1]: `M0,0,C0.104,0.204,0.492,1,1,1`,
	[Ease.POWER_IN_OUT_1]: `M0,0,C0.272,0,0.472,0.455,0.496,0.496,0.574,0.63,0.744,1,1,1`,

	[Ease.BACK_IN]: `M0,0,C0.192,0,0.33,-0.152,0.522,-0.078,0.641,-0.031,0.832,0.19,1,1`,
	[Ease.BACK_OUT]: `M0,0,C0.128,0.572,0.257,1.016,0.512,1.09,0.672,1.136,0.838,1,1,1`,
	[Ease.BACK_IN_OUT]: `M0,0,C0.068,0,0.128,-0.061,0.175,-0.081,0.224,-0.102,0.267,-0.107,0.315,-0.065,0.384,-0.004,0.449,0.253,0.465,0.323,0.505,0.501,0.521,0.602,0.56,0.779,0.588,0.908,0.651,1.042,0.705,1.082,0.748,1.114,0.799,1.094,0.817,1.085,0.868,1.061,0.938,0.998,1,1`,

	[Ease.BOUNCE_IN]: `M0,0,C0,0,0.013,0.008,0.021,0.011,0.029,0.014,0.035,0.015,0.044,0.015,0.052,0.015,0.058,0.014,0.066,0.012,0.076,0.009,0.085,0.003,0.091,0.001,0.096,0.007,0.104,0.017,0.113,0.027,0.121,0.035,0.127,0.04,0.136,0.047,0.144,0.052,0.149,0.055,0.158,0.058,0.166,0.061,0.172,0.062,0.18,0.062,0.188,0.062,0.195,0.061,0.203,0.059,0.211,0.056,0.218,0.052,0.225,0.047,0.233,0.042,0.238,0.038,0.245,0.031,0.256,0.02,0.269,0.005,0.273,0.001,0.279,0.017,0.298,0.069,0.318,0.109,0.333,0.14,0.344,0.158,0.363,0.187,0.37,0.198,0.377,0.205,0.386,0.215,0.394,0.222,0.399,0.227,0.408,0.233,0.416,0.239,0.423,0.243,0.432,0.246,0.44,0.249,0.446,0.25,0.455,0.25,0.463,0.249,0.469,0.248,0.477,0.246,0.486,0.243,0.492,0.239,0.5,0.234,0.508,0.228,0.514,0.223,0.521,0.215,0.531,0.206,0.537,0.199,0.545,0.188,0.562,0.161,0.573,0.144,0.588,0.114,0.609,0.072,0.632,0.01,0.636,0.001,0.64,0.019,0.663,0.146,0.683,0.241,0.699,0.321,0.709,0.363,0.728,0.441,0.744,0.509,0.754,0.545,0.773,0.611,0.789,0.666,0.798,0.696,0.818,0.75,0.833,0.793,0.843,0.818,0.863,0.858,0.878,0.889,0.889,0.907,0.908,0.936,0.915,0.948,0.922,0.954,0.931,0.964,0.939,0.972,0.944,0.977,0.953,0.983,0.961,0.989,0.967,0.992,0.976,0.995,0.984,0.998,1,1,1,1`,
	[Ease.BOUNCE_OUT]: `M0,0,C0.14,0,0.242,0.438,0.272,0.561,0.313,0.728,0.354,0.963,0.362,1,0.37,0.985,0.414,0.873,0.455,0.811,0.51,0.726,0.573,0.753,0.586,0.762,0.662,0.812,0.719,0.981,0.726,0.998,0.788,0.914,0.84,0.936,0.859,0.95,0.878,0.964,0.897,0.985,0.911,0.998,0.922,0.994,0.939,0.984,0.954,0.984,0.969,0.984,1,1,1,1`,
	[Ease.BOUNCE_IN_OUT]: `M0,0,C0,0,0.014,0.007,0.022,0.007,0.03,0.007,0.039,0.002,0.045,0,0.051,0.006,0.058,0.017,0.068,0.023,0.075,0.028,0.083,0.03,0.09,0.031,0.097,0.031,0.104,0.029,0.11,0.025,0.12,0.018,0.129,0.007,0.135,0.001,0.135,0.002,0.137,0.002,0.137,0.003,0.144,0.019,0.15,0.036,0.16,0.056,0.167,0.072,0.173,0.082,0.183,0.095,0.19,0.104,0.196,0.111,0.205,0.117,0.211,0.121,0.219,0.125,0.227,0.124,0.235,0.124,0.243,0.121,0.25,0.117,0.258,0.111,0.264,0.104,0.271,0.095,0.28,0.082,0.286,0.073,0.293,0.058,0.304,0.037,0.316,0.005,0.318,0,0.32,0.011,0.333,0.083,0.345,0.136,0.354,0.182,0.36,0.206,0.371,0.25,0.381,0.287,0.386,0.307,0.398,0.343,0.407,0.371,0.413,0.387,0.425,0.414,0.432,0.431,0.437,0.441,0.446,0.456,0.453,0.468,0.459,0.475,0.468,0.484,0.473,0.49,0.478,0.494,0.485,0.496,0.497,0.501,0.508,0.5,0.519,0.505,0.528,0.51,0.534,0.517,0.541,0.526,0.551,0.538,0.557,0.548,0.565,0.563,0.575,0.585,0.581,0.599,0.59,0.622,0.599,0.647,0.603,0.662,0.611,0.688,0.621,0.722,0.627,0.741,0.635,0.775,0.654,0.863,0.678,0.981,0.681,0.999,0.683,0.994,0.695,0.962,0.706,0.941,0.713,0.926,0.719,0.917,0.728,0.904,0.735,0.895,0.741,0.888,0.75,0.882,0.756,0.878,0.764,0.875,0.772,0.875,0.78,0.874,0.788,0.877,0.795,0.882,0.804,0.888,0.811,0.896,0.818,0.906,0.828,0.92,0.833,0.93,0.841,0.946,0.851,0.966,0.86,0.991,0.863,0.999,0.867,0.995,0.877,0.982,0.887,0.975,0.894,0.971,0.902,0.968,0.91,0.968,0.917,0.968,0.925,0.972,0.932,0.977,0.941,0.983,0.948,0.993,0.954,0.999,0.96,0.997,0.969,0.992,0.976,0.992,0.984,0.992,1,1,1,1`,
};

/**
 * Animation data parser, used on Client, by BuildingBlocks.
 * The main purpose of this utility is to produce css animation @keyframes
 * Animation data is based on its own raw-keyframe format, where css properties
 * described mostly just like in normal css animations, despite transformation-related properties:
 * 		translateX(10px) — x: 10
 * 		rotate(45deg) - rotation: 45
 * 		scale(2) - scale: 2
 * Each property change can have its own easing (which is accomplished by merging individual props timelines
 * into one main timeline). Additionaly, keyframes can have their own animation-events, which are fired in
 * required time after animation start. These animation events are used to link different animation of different
 * elements (see 'event' and 'trigger' below)
 *
 * data = {
 *		duration: '5', — {string} time in seconds
 *		delay: '2', - {string} time in seconds
 *		id: 'name', — {string}
 *		trigger: ANIMATION_TRIGGER.ON_VIEWPORT, — {string} animation starting event
 *		ease: Ease.POWER_0, {string} animation timing function
 *		keyframes: {
 *			keyframeId: {
 *					position: '0', {string} position point on animation timeline in percents, from 0 to 100
 *					event: '', {string} any string for event name (window.dispatchEvent(new Event('...')))
 *					props: {
 *						propId: {
 *							name: 'propName',
 *							value: 'propValue.propUnit',
 *							ease: 'easeFuncName',
 *						},
 *					},
 *				},
 *			},
 *		},
 *	}
 *
 * @param data {object}
 * @param name {string}
 */

export type PropertyData = {
	name: string;
	value: string;
	ease: string;
};

export type KeyframeData = {
	position: string;
	event: string;
	eventId: string;
	props: { [key: string]: PropertyData };
};

export type AnimationData = {
	id: string;
	duration: string;
	delay: string;
	trigger: ValuesType<typeof ANIMATION_TRIGGER> | string;
	name: string;
	events?: Array<{ name: string; eventId: string; delay: number }>;
	ease: string;
	loop: string;
	reverse: string;
	keyframes: Record<string, KeyframeData>;
	sortedKeyframes?: Array<[string, Record<string, string>]>;
};

const PROP_NAME = {
	[ANIMATION_EFFECT_ID.BG_COLOR]: 'background-color',
	[ANIMATION_EFFECT_ID.COLOR]: 'color',
	[ANIMATION_EFFECT_ID.OPACITY]: 'opacity',
	[ANIMATION_EFFECT_ID.ROTATE]: 'rotation',
	[ANIMATION_EFFECT_ID.SCALE]: 'scale',
	[ANIMATION_EFFECT_ID.SCALE_X]: 'scaleX',
	[ANIMATION_EFFECT_ID.SCALE_Y]: 'scaleY',
	[ANIMATION_EFFECT_ID.X]: 'x',
	[ANIMATION_EFFECT_ID.Y]: 'y',
};

export const parseAnimation = (data: AnimationData, name: string = 'animation') => {
	if (!data) {
		return null;
	}

	const keyframes: AnimationData['keyframes'] = reduce(
		data.keyframes,
		(acc: AnimationData['keyframes'], keyframe: KeyframeData, id: string) => {
			const { position } = keyframe;

			acc[id] = {
				...keyframe,
				props: reduce(
					keyframe.props,
					(a, prop: PropertyData) => {
						const n = PROP_NAME[prop.name] || prop.name;
						a[n] = { ...prop, name: n };
						return a;
					},
					{}
				),
				position,
			};
			return acc;
		},
		{}
	);
	const sortedKeyframes: Array<[string, KeyframeData]> = map(
		sortBy(keyframes, (a: KeyframeData) => +a.position),
		(keyframe: KeyframeData) => [keyframe.position, keyframe]
	);
	const tlRaw = {};
	const tlFinal = {};
	const events: AnimationData['events'] = [];

	// Go though all keyframes and prepare prop-changing timelines,
	// which are merged into single one:
	// TODO: can we omit all animations job in editor? probably we can do it at least for a selected element only...
	forEach(sortedKeyframes, ([time, keyframeData]) => {
		const startTime = parseFloat(time) * 0.01;
		const totalDuration = parseFloat(data.duration) * 1000;
		const keyframeTime = (totalDuration * parseFloat(time)) / 100;

		if (keyframeData.event) {
			events.push({ name: keyframeData.event, eventId: keyframeData.eventId, delay: keyframeTime || 0 });
		}

		forEach(keyframeData.props, (propData: PropertyData) => {
			const propName = propData.name;
			const easeCurve = EaseCurve[propData.ease || data.ease || Ease.POWER_0];
			const parsedValue = parseFieldValue(propName, propData.value);
			const unit = get(parsedValue, 'unit', '');
			const numberValue = parseFloat(`${get(parsedValue, 'number', 0) || get(parsedValue, 'string', 0)}`);
			const stringValue = get(parsedValue, 'number', '') || get(parsedValue, 'string', '');
			const startValue = !Number.isNaN(numberValue) ? numberValue : stringValue;
			// @ts-expect-error ts-migrate FIXME
			const { targetTime, targetValue } = reduce(
				sortedKeyframes,
				(acc, [t, kD]: [string, KeyframeData]) => {
					// Looking for the next (not the last one) keyframe with current 'propName'
					const tT = parseFloat(t) * 0.01;
					const targetProp = find(kD.props, { name: propName });

					if (tT <= startTime) return {};
					if (!isEmpty(acc)) return acc;
					if (targetProp) {
						const parsedTargetValue = parseFieldValue(propName, targetProp.value) ?? {
							number: 0,
							string: '',
						};
						const parsedTargetNumberValue = parseFloat(
							`${parsedTargetValue.number || parsedTargetValue.string}`
						);

						return {
							targetTime: tT,
							targetValue: !Number.isNaN(parsedTargetNumberValue)
								? parsedTargetNumberValue
								: parsedTargetValue.number || parsedTargetValue.string,
						};
					}
					return acc;
				},
				{}
			);

			// making prop-changing curve, where X-axis is used for time and Y-axis for value:
			if (targetTime !== undefined && targetValue !== undefined) {
				const steps = 20;
				const easingDuration = targetTime - startTime;
				const valueDif = targetValue - +startValue;
				const easeCurvePath = canvas.path(easeCurve);
				const length = easeCurvePath.length();

				if (!Number.isNaN(numberValue)) {
					for (let eased = 0; eased < 1; eased += 1 / steps) {
						const { x, y } = easeCurvePath.pointAt(eased * length);
						const tlTargetTime = `${Math.round((startTime + x * easingDuration) * 1000) / 10}%`;
						const tlData = tlRaw[tlTargetTime];

						tlRaw[tlTargetTime] = merge(tlData, {
							[propName]: `${Math.round((+startValue + y * valueDif) * 1000) / 1000}${unit}`,
						});
					}
				} else {
					const tlStartTime = `${Math.round(startTime * 1000) / 10}%`;
					const tlTargetTime = `${Math.round((startTime + easingDuration) * 1000) / 10}%`;

					tlRaw[tlStartTime] = merge(tlRaw[tlStartTime], { [propName]: startValue });
					tlRaw[tlTargetTime] = merge(tlRaw[tlTargetTime], { [propName]: targetValue });
				}
			} else {
				// FIXME: seems like it is possible that targetTime and targetValue are undefined...
				// throw new Error(`animation parsing error: no targetValue for ${propName}`);
			}
		});
	});

	// sometimes after processing prop-changing curve there is no final point...
	// just need to add it manualy in such case
	tlRaw['100%'] = reduce(
		get(find(keyframes, { position: '100' }), 'props'),
		(acc, propData: PropertyData) => {
			acc[propData.name] = propData.value;
			return acc;
		},
		{}
	);

	// till now we still have some non-css props (transformations) in resulting timeline,
	// convert them to css transform: matrix():
	forEach(tlRaw, (keyframeData, time: string) => {
		const transformationKeys = ['x', 'y', 'rotation', 'scale', 'scaleX', 'scaleY'];
		const updatedKeyframe = {};

		// collect all current 'in-progress' transformations
		const sortedKeys = Object.keys(tlRaw).sort((a, b) => parseFloat(a) - parseFloat(b));
		const transformationSearchingKeys = transformationKeys.concat();
		const currentTransformations = {};
		// search transform props from current time to start:
		const startToCurrent = sortedKeys.concat();
		const currentToEnd = sortedKeys.concat();

		currentToEnd.splice(0, sortedKeys.indexOf(time));
		startToCurrent.splice(sortedKeys.indexOf(time));

		forEach(startToCurrent.reverse(), timeKeyStart => {
			forEach(tlRaw[timeKeyStart], (propData, propName) => {
				if (transformationSearchingKeys.includes(propName)) {
					// search transform props from current time to end:
					forEach(currentToEnd, timeKeyTarget => {
						if (currentTransformations[propName]) return;
						forEach(tlRaw[timeKeyTarget], (propDataTarget, propNameTarget) => {
							if (propNameTarget === propName && transformationSearchingKeys.includes(propName)) {
								currentTransformations[propName] = {
									start: { time: timeKeyStart, value: propData },
									end: { time: timeKeyTarget, value: propDataTarget },
								};
							}
						});
					});

					if (currentTransformations[propName]) {
						transformationSearchingKeys.splice(transformationSearchingKeys.indexOf(propName), 1);
					}
				}
			});
		});

		forEach(keyframeData, (propData, propName) => {
			// @ts-expect-error ts-migrate FIXME
			if (transformationKeys.includes(propName)) {
				const rad = Math.PI / 180;
				// @ts-expect-error ts-migrate FIXME
				const tx = parseFloat(keyframeData.x || getCurrentTransform(currentTransformations, 'x', time) || 0);
				// @ts-expect-error ts-migrate FIXME
				const ty = parseFloat(keyframeData.y || getCurrentTransform(currentTransformations, 'y', time) || 0);
				const a = parseFloat(
					// @ts-expect-error ts-migrate FIXME
					keyframeData.rotation * rad || getCurrentTransform(currentTransformations, 'rotation', time) || 0
				);
				const scaleX = parseFloat(
					// @ts-expect-error ts-migrate FIXME
					keyframeData.scaleX ||
						// @ts-expect-error ts-migrate FIXME
						keyframeData.scale ||
						getCurrentTransform(currentTransformations, 'scaleX', time) ||
						getCurrentTransform(currentTransformations, 'scale', time) ||
						100
				);
				const scaleY = parseFloat(
					// @ts-expect-error ts-migrate FIXME
					keyframeData.scaleY ||
						// @ts-expect-error ts-migrate FIXME
						keyframeData.scale ||
						getCurrentTransform(currentTransformations, 'scaleY', time) ||
						getCurrentTransform(currentTransformations, 'scale', time) ||
						100
				);

				const matrix = new TransformMatrix2D();
				const tMatrix = new TransformMatrix2D(1, 0, 0, 1, tx, ty);
				const rMatrix = new TransformMatrix2D(Math.cos(a), Math.sin(a), -Math.sin(a), Math.cos(a), 0, 0);
				const sMatrix = new TransformMatrix2D(scaleX / 100, 0, 0, scaleY / 100, 0, 0);

				matrix.multiply(tMatrix).multiply(rMatrix).multiply(sMatrix);

				// @ts-expect-error ts-migrate FIXME
				updatedKeyframe.transform = `${matrix.toCSS()};`;
			} else {
				// @ts-expect-error ts-migrate FIXME
				updatedKeyframe[propName] = `${propName === 'opacity' ? +propData / 100 : propData};`;
			}
		});

		tlFinal[time] = updatedKeyframe;
	});

	const result = {
		id: data.id,
		name,
		duration: parseFloat(data.duration) * 1000 || 0,
		delay: parseFloat(data.delay) * 1000 || 0,
		loop: data.loop === 'true',
		reverse: data.reverse === 'true',
		trigger: data.trigger,
		ease: data.ease,
		style: `@keyframes ${name} ${JSON.stringify(tlFinal)
			.replace(/['"]+/g, '')
			.replace(/%:+/g, '%')
			.replace(/;,+/g, ';')
			.replace(/},+/g, '}')}`,
		sortedKeyframes: reduce(
			tlFinal,
			(acc, current, time) => {
				const withNoSemi = reduce(
					current,
					(a, c, n) => {
						// @ts-expect-error ts-migrate FIXME
						a[n] = c.replace(/;+/g, ''); // eslint-disable-line no-param-reassign
						return a;
					},
					{}
				);
				acc.push([time, withNoSemi]);
				return acc;
			},
			[] as Exclude<AnimationData['sortedKeyframes'], undefined>
		),
		events,
	};

	return result;
};

/*
 transformations = {
	 x: {
		 start: { time: '5.6%', value: '-100px' },
		 end: { time: '35.2%', value: '20px' },
	 }
 }
 */
function getCurrentTransform(transformations, propKey, time) {
	const data = transformations[propKey];

	if (data) {
		const duration = parseFloat(data.end.time) - parseFloat(data.start.time);
		const diff = parseFloat(data.end.value) - parseFloat(data.start.value);
		const result =
			parseFloat(data.start.value) + ((parseFloat(time) - parseFloat(data.start.time)) / duration) * diff;

		return result;
	}

	return undefined;
}

export type AnimationPlaybackPayload =
	| { action: 'onAnswer'; answerNodeId: string; isCorrect: boolean }
	| { action: 'onMultipleAnswerSelect'; answerNodeId: string; isCorrect?: boolean }
	| { action: 'onMultipleAnswerSubmit' }
	| { action: 'onFormSubmit' }
	| { action: 'onSlideChangeStart'; _id: string };

export type AnimationPlaybackEvent = { type: 'playback'; data: AnimationPlaybackPayload };

export const AnimationEvEm = new EventEmitter<AnimationPlaybackEvent>();
