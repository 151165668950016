import type { CmsModel } from 'types/cms';
import cmsUtils from 'common/utils/cms';
import { CMS } from 'admin/constants/actions';
import { setCollectionItems, setCollections } from 'admin/actions/cms';

type ReducerState = {
	collections: NonNullable<CmsModel['collections']>;
	items: CmsModel['items'];
};

type ReducerActions = ReturnType<typeof setCollections> | ReturnType<typeof setCollectionItems>;

const initialState: ReducerState = {
	collections: [],
	items: {},
};

export default function cmsReducer(state = initialState, { type, payload }: ReducerActions): ReducerState {
	switch (type) {
		case CMS.SET_COLLECTIONS.FULFILLED:
			return { ...state, collections: [...new Set([...state.collections, ...payload])] };

		case CMS.SET_ITEMS.FULFILLED: {
			const itemsRecord = cmsUtils.transformItemsArrayToObject(payload.items);
			cmsUtils.applyCmsReducerUpdate({ items: { [payload.collectionId]: itemsRecord }, collections: [] }, state);
			return state;
		}

		default:
			return state;
	}
}
