/**
 * This file contains functions to process and duplicate repeatable CMS components within a card or story.
 * The main function, processRepeatableComponents, handles the duplication and recursive processing of child
 * components based on their CMS references and context.
 */

import {
	StoryVersionType,
	BBModel,
	BBSymbolLink,
	BBStylesProp,
	BBOtherProp,
	StorySymbols,
	StoryStep,
} from 'types/story';
import type { CmsCollectionDataItem, CmsModel } from 'types/cms';
import produce, { Draft } from 'immer';
import { set } from 'lodash';
import cms from 'common/utils/cms';
import CmsRepeatableStateManager from 'utils/cms/cms-repeatable-state-manager';
import { appLog, deepClone } from 'common/utils/helpers';
import { updateId } from 'common/utils/generate-id';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalk } from 'common/utils/blocks/component-walk';
import type { CmsChangePageEvent, CmsChangeFilterEvent } from 'common/utils/cms/cms-event-emitter';
import { getSymbolModel } from 'common/utils/blocks/get-symbol-model';
import { createIdsMap, updateReferenceIds } from 'common/utils/blocks/update-reference-ids';
import { findComponentBy } from 'common/utils/blocks/find-component-by';
import type { GenerateIdCallback } from 'common/utils/blocks/symbol-manager';
import { isSymbolLink, isSymbol, getInstanceByRef, isSymbolChild } from 'common/utils/blocks/symbol';

// Test cases:
//   === Plain component ===
// - ✅Check single repeatable element
// - ✅Check repeatable with a children
// - ⚪️Check repeatable inside repeatable, not supported yet
// - ✅With nested linked Overlay
//   === Symbol component ===
// - ✅Check if the master symbol is repeatable
// - ✅Check if the instance symbol is repeatable
// - ✅Check if symbol is child of repeatable
// - ✅Check if the symbol child is repeatable. Done: repeat is ignored.
// - ✅With nested linked Overlay

const log = appLog.extend('CmsRepeatableComponentsProcessor');

type DraftElement = Draft<BBModel>;

type RepeatableContext = { data: CmsCollectionDataItem; collectionId: string };

interface ProcessRepeatableCommonParams {
	symbols: StorySymbols;
	items: CmsModel['items'];
}

interface ProcessCardPaginationParams extends ProcessRepeatableCommonParams {
	type: 'update-card-pagination';
	data: BBModel[];
	pagination: CmsChangePageEvent['data'];
}

interface ProcessCardFilterParams extends ProcessRepeatableCommonParams {
	type: 'update-card-filter';
	data: BBModel[];
	filter: CmsChangeFilterEvent['data'];
}

interface ProcessChildrenParams extends ProcessRepeatableCommonParams {
	type: 'update-children';
	data: BBModel[];
	// filter: CmsChangeFilterEvent['data'];
	// pagination: CmsChangePageEvent['data'];
}

interface ProcessStoryParams extends ProcessRepeatableCommonParams {
	type: 'update-story';
	data: StoryVersionType;
}

type ProcessRepeatableComponentsParams =
	| ProcessCardPaginationParams
	| ProcessCardFilterParams
	| ProcessStoryParams
	| ProcessChildrenParams;

/**
 * Process repeatable components in a card or a story and return a new card or story with duplicated components
 * fulfilled with proper references to the CMS data.
 */
export function processRepeatableComponents<T extends ProcessRepeatableComponentsParams>(
	params: T
): { data: T['data']; isModified: boolean } {
	if (params.type !== 'update-children') {
		return {
			data: params.data,
			isModified: false,
		};
	}

	const { items: collectionItems, symbols = {} } = params;
	const duplicatedElementsIds = new Set<string>();
	const duplicatedElementsCtx = new Map<string, RepeatableContext>();
	const newIds = createIdsMap();
	const collectNewIds: GenerateIdCallback = ({ oldId, ...data }) => newIds.set(oldId, data);
	let isModified = false;

	const result = produce(params, draft => {
		if (draft.type === 'update-story') {
			processSteps(draft.data.data.steps);
		} else if (draft.type === 'update-card-pagination' || draft.type === 'update-card-filter') {
			processElements(draft.data);
		} else if (draft.type === 'update-children') {
			processElements(draft.data);
		}

		duplicatedElementsCtx.clear();
		duplicatedElementsIds.clear();
	});

	return {
		data: result.data as T['data'],
		isModified,
	};

	function processElements(elements: BBModel[]) {
		return elements.forEach(element => processElement(element));
	}

	function processSteps(steps: StoryStep[]) {
		return steps.forEach(step => step.cards.forEach(card => processElements(card.elements)));
	}

	/**
	 * Processes a draft element by handling its CMS references and duplicating repeatable child components.
	 * It recursively processes each child element.
	 *
	 * Detailed Functionality:
	 *
	 * * Context Handling
	 *   If a context is provided, it modifies text nodes, styles, and uniform properties based on the context.
	 *
	 * * Child Processing:
	 *   If the draft element has children, it iterates over them. If a child is a repeatable component
	 *   (determined by the presence of a collectionId), it duplicates the child and processes the duplicates.
	 *
	 * * Duplication Logic:
	 *   Uses a while loop to handle the addition of new elements to the iterable array. It duplicates the child
	 *   components, generates new IDs, updates references, and replaces the original child with the duplicates.
	 *   But it does not inject any data, only generates new elements with proper references to data.
	 *
	 * * Recursive Processing:
	 *   Recursively processes each child element, passing the context if available
	 */
	function processElement(draftElement: DraftElement, context?: RepeatableContext) {
		// replace references based on context
		if (context) {
			// handle text cms references
			modifyTextNodes({ draftElement, context, symbols });

			// handle styles, other cms references
			modifyStyleOrOtherProps({ draftElement, context, symbols });

			// handle uniform props cms references
			modifyUniformProps({ draftElement, context, symbols });
		}

		if (Array.isArray(draftElement.children)) {
			let childIndex = 0;

			/*
			 This code duplicates repeatable child components and recursively processes them.
			 If a child has a collectionId, it's a repeatable component and should be duplicated.
			 It's important to use a `while` loop because we're adding new elements to the iterable array.
			 */
			while (childIndex < draftElement.children.length) {
				const rawChild = draftElement.children[childIndex];
				const child = isSymbolLink(rawChild) ? getSymbolModel(rawChild, symbols) : rawChild;
				const { collectionId } = child.uiConfig.componentProps;

				const collectionData = collectionId ? Object.values(collectionItems?.[collectionId] ?? {}) : null;
				const isChildOfRepeatable = context !== undefined;
				const isDuplicate = duplicatedElementsIds.has(child._id);
				const shouldDuplicate =
					collectionId &&
					!!collectionData?.length &&
					!isChildOfRepeatable &&
					!isDuplicate &&
					!isSymbolChild(child);

				if (shouldDuplicate) {
					log('duplicate:start', { _id: child._id, type: child.type, collectionId });
					const duplicatedElements: (BBModel | BBSymbolLink)[] = [];

					const repeatableResult = CmsRepeatableStateManager.handleUpdate({
						element: child,
						collectionId,
						collectionData,
						symbols,
						...(params.type === 'update-card-filter' // Filter particular component data
							? { type: 'filter', filter: params.filter }
							: params.type === 'update-card-pagination' // Pagination particular component data
								? { type: 'page', pagination: params.pagination }
								: { type: 'init' }),
					});

					const sliceSize = repeatableResult.dataSlice.length;
					if (sliceSize && repeatableResult.state) {
						for (let sliceIndex = 0; sliceIndex < sliceSize; sliceIndex += 1) {
							const { originalComponent } = repeatableResult.state;
							let newComponent: BBModel = deepClone(originalComponent);

							// generate new id for each component
							componentWalk([newComponent], function walk({ component }) {
								const oldId = component._id;
								updateId(component);
								collectNewIds({ oldId, newId: component._id, type: component.type });
								componentWalk(component.children, walk);
							});

							[newComponent] = updateReferenceIds([newComponent], newIds, symbols);
							(newComponent as any).__isDuplicated = true;
							duplicatedElementsIds.add(newComponent._id);
							duplicatedElements.push(newComponent);

							// collect data for each duplicated element
							duplicatedElementsCtx.set(newComponent._id, {
								data: repeatableResult.dataSlice[sliceIndex],
								collectionId,
							});

							// store link with original element
							CmsRepeatableStateManager.originMap.set(newComponent._id, originalComponent._id);
						}

						if (repeatableResult.state?.strategy === 'page') {
							// replace current and all duplicates with new elements
							draftElement.children.splice(
								childIndex,
								repeatableResult.deleteCount,
								...(duplicatedElements as BBModel[])
							);
						} else if (repeatableResult.state?.strategy === 'infinite') {
							// todo: implement infinite scroll
						}

						isModified = true;

						// Since we've replaced elements including current one, we need to loop again with it
						// without incrementing the index
						// eslint-disable-next-line no-continue
						continue;
					}
				}

				// process children
				processElement(child, context ?? duplicatedElementsCtx.get(child._id));
				childIndex += 1;
			}
		}
	}
}

type ApplyModificationParams = { draftElement: Draft<BBModel>; path: string; value: any; symbols: StorySymbols };

// Applies modifications to a draft element based on the provided path and value.
function applyModification(params: ApplyModificationParams) {
	// log('applyModification', { params });
	if (isSymbol(params.draftElement)) {
		const instance = getInstanceByRef(params.draftElement.symbol, params.symbols);
		if (!instance) return;
		// find is because of draftElement might be a symbol child
		const target = findComponentBy([instance], { path: '_id', value: params.draftElement._id });
		if (!target?.component) return;
		set(target.component, params.path, params.value);
	} else {
		set(params.draftElement, params.path, params.value);
	}
}

type ModifyFn = (params: { draftElement: Draft<BBModel>; context: RepeatableContext; symbols: StorySymbols }) => void;

// Modifies text nodes within a draft element based on the provided context.
const modifyTextNodes: ModifyFn = ({ draftElement, context, symbols }) => {
	if (!Array.isArray(draftElement.children)) {
		Object.keys(draftElement.children).forEach(state => {
			Object.keys(draftElement.children[state]).forEach(platform => {
				const originalValue = draftElement.children[state][platform];
				const ref = cms.parseReference(originalValue, false);

				if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
					// replace "any" dataId with an actual dataId
					applyModification({
						path: `children.${state}.${platform}`,
						value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
						draftElement,
						symbols,
					});
				}
			});
		});
	}
};

// Modifies style or other properties within a draft element based on the provided context.
const modifyStyleOrOtherProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftObjEntries = [
		['styles', draftElement.uiConfig.componentProps.styles],
		['other', draftElement.uiConfig.componentProps.other],
	] as const;

	draftObjEntries.forEach(([draftObjKey, draftObj]) => {
		Object.entries(draftObj).forEach(([state, platforms]) => {
			Object.entries(platforms).forEach(([platform, entries]) => {
				Object.entries(entries as BBStylesProp | BBOtherProp).forEach(([key, value]) => {
					const ref = cms.parseReference(value, false);

					if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
						// Replace "any" dataId with an actual dataId
						applyModification({
							path: `uiConfig.componentProps.${draftObjKey}.${state}.${platform}.${key}`,
							value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
							draftElement,
							symbols,
						});
					}
				});
			});
		});
	});
};

// Modifies uniform properties within a draft element based on the provided context.
const modifyUniformProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftComponentProps = draftElement.uiConfig.componentProps;
	Object.keys(draftComponentProps).forEach(prop => {
		if (prop in UNIFORM_PROPS) {
			const originalValue = draftComponentProps[prop];
			const ref = cms.parseReference(originalValue, false);
			if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
				// replace "any" dataId with an actual dataId
				applyModification({
					path: `uiConfig.componentProps.${prop}`,
					value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
					draftElement,
					symbols,
				});
			}
		}
	});
};
