import dayjs from 'dayjs';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { GetVideosParams, GetVideosResult } from 'src/types';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { PAGINATE_VIDEOS_PAGE_SIZE } from 'admin/constants/common';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { QUERY_KEYS } from '../constants';

export const usePaginateVideos = (params: Omit<GetVideosParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { size = PAGINATE_VIDEOS_PAGE_SIZE, term, withStats = false } = params;
	const from = dayjs().startOf('month').format('YYYY-MM-DD');
	const to = dayjs().endOf('day').format('YYYY-MM-DD');

	return useInfiniteQuery({
		queryKey: [QUERY_KEYS.PAGINATE_VIDEOS, organizationId, term, `${withStats}`].filter(Boolean),
		queryFn: async ({ pageParam }) => {
			const query = {
				size,
				...(withStats && { withStats, from, to }),
				page: pageParam,
				...(term && { term }),
			};

			const response: ApiV2Response<GetVideosResult> = await videos.paginate
				.params({ organizationId })
				.send(query);

			return response.body!;
		},
		initialPageParam: 1,
		select: result => {
			const flatArray = result.pages.flatMap(page => page.transcodings);
			const uniqueById = [...new Map(flatArray.map(item => [item.id, item])).values()];

			return {
				...result,
				items: uniqueById,
			};
		},
		getNextPageParam: (lastPage, pages) =>
			lastPage !== null && lastPage.transcodings.length < size ? undefined : pages.length + 1,
	});
};
