import React, { useContext, useState } from 'react';
import { Steps, ConfigProvider, ThemeConfig } from 'antd';
import { CmsModel, CmsCollectionFieldItem, CmsFieldType } from 'types/cms';
import cmsUtils from 'common/utils/cms';
import { MODAL } from 'admin/constants/common';
import { useAdminSelector } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { useListCollectionItems } from 'admin/queries/cms/list-collection-items';
import { Select, Hint } from 'admin/components/common/Form';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectCollections, selectItems } from 'admin/reducers/cms/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { Column, Grid } from 'admin/components/common/Grid';
import css from './CmsPropBindModalData.scss';

enum StepIndex {
	PROPERTY,
	COLLECTION,
	DATA,
}

const getDataKeyOptions = (fields: CmsCollectionFieldItem[], allowedTypes: CmsFieldType[]) => {
	if (!fields) return [];
	return fields.filter(d => allowedTypes.includes(d.type)).map(f => ({ value: f.key, label: f.name }));
};

const getCollectionDataIdOptions = (collectionId: string, items: CmsModel['items']) => {
	const collectionData = items?.[collectionId];
	if (!collectionData) return [];
	return Object.values(collectionData).map((d, i) => ({ value: d.id, label: `${i}` }));
};

const antdTheme: ThemeConfig = {
	token: {
		colorText: '#fff',
		colorTextDescription: 'rgba(255, 255, 255, 0.45)',
		colorTextDisabled: 'blue',
		colorTextQuaternary: 'red',
		colorBorderSecondary: 'rgba(255, 255, 255, 0.18)',
	},
};

type ContentProps = ModalManagerProvidedProps<MODAL.CMS_PROP_BIND_MODAL>;

const ModalContent: React.FC<ContentProps> = props => {
	const { theme } = useContext(ThemeContext);
	const cmsCollections = useAdminSelector(selectCollections);
	const cmsItems = useAdminSelector(selectItems);
	const [currentStep, setCurrentStep] = useState(props.data.view === 'edit' ? StepIndex.DATA : StepIndex.PROPERTY);
	const [property, setProperty] = useState(props.data.properties[0].value);
	const [collectionId, setCollectionId] = useState(props.data.collectionId);

	// Fetch collection items for the selected collection
	useListCollectionItems({ collectionId: collectionId ?? '', pageSize: cmsUtils.defaultProps.collectionPageSize });

	const dataKeyOptions = getDataKeyOptions(
		cmsCollections?.find(c => c.id === collectionId)?.fields ?? [],
		props.data.properties.find(p => p.value === property)?.fieldType ?? []
	);
	const defaultDataKey = props.data.view === 'edit' ? props.data.dataKey : dataKeyOptions[0]?.value;
	const [dataKey, setDataKey] = useState(dataKeyOptions.find(opt => opt.value === defaultDataKey)?.value);

	const collectionDataIdOptions = getCollectionDataIdOptions(collectionId ?? '', cmsItems);
	const defaultDataId = props.data.view === 'edit' ? props.data.dataId : cmsUtils.DATA_ANY;
	const [dataId, setDataId] = useState(defaultDataId);

	const onInputChange = (value: string, callback: (v: string) => void) => callback(value);

	const collectionOptions = React.useMemo(() => {
		return (
			cmsCollections?.map(c => ({
				value: c.id,
				label: `${c.name}${c.id === props.data.repeatableCollectionId ? ' (Repeats)' : ''}`,
			})) ?? []
		);
	}, [cmsCollections, props.data.repeatableCollectionId]);

	const steps = [
		{
			title: 'Component property',
			description: props.data.properties.find(p => p.value === property)?.label,
			isValid: !!property,
			content: (
				<Select
					options={props.data.properties}
					value={property}
					theme={theme}
					eventListeners={{ onChange: v => onInputChange(v, setProperty) }}
				/>
			),
		},
		{
			title: 'Collection',
			description: collectionOptions.find(p => p.value === collectionId)?.label,
			isValid: !!property && !!collectionId,
			content: (
				<Select
					options={collectionOptions}
					value={collectionId}
					theme={theme}
					eventListeners={{
						onChange: v =>
							onInputChange(v, async nextCollectionId => {
								setCollectionId(nextCollectionId);
							}),
					}}
				/>
			),
		},
		{
			title: 'Collection data',
			description: dataKeyOptions.find(p => p.value === dataKey)?.label,
			isValid: !!property && !!collectionId && !!dataKey,
			content: (
				<>
					<Select
						label={{ children: 'Collection data column' }}
						options={dataKeyOptions}
						value={dataKey}
						theme={theme}
						eventListeners={{ onChange: v => onInputChange(v, setDataKey) }}
					/>
					<br />
					<br />
					<Select
						label={{ children: 'Collection data row [optional]' }}
						options={collectionDataIdOptions}
						value={dataId}
						theme={theme}
						eventListeners={{ onChange: setDataId }}
						menuPlacement="top"
						maxMenuHeight={200}
						placeholder={cmsUtils.DATA_ANY}
					/>
					<div style={{ height: 6 }} />
					<Hint theme={theme} text="Keep it empty if when you repeat from collection" />
				</>
			),
		},
	];

	const lastStepIndex = steps.length - 1;

	const isSaveDisabled = !collectionId || !dataKey || !property;

	const handleSave = () => {
		props.data.onSave({
			property,
			collectionId: collectionId!,
			dataKey: dataKey!,
			dataId,
		});
		props.close();
	};

	return (
		<>
			<ModalBody>
				<Grid columns="14" gap="large">
					<Column colSpan="6">{steps[currentStep].content}</Column>

					<Column colSpan="8" style={{ paddingTop: 16 }}>
						<ConfigProvider theme={antdTheme}>
							<Steps
								className={css.steps}
								size="small"
								direction="vertical"
								current={currentStep}
								items={steps.map((s, i) => ({
									key: `step-${s.title}`,
									title: s.title,
									description: s.isValid ? s.description : undefined,
									disabled: !s.isValid,
								}))}
								onChange={setCurrentStep}
							/>
						</ConfigProvider>
					</Column>
				</Grid>
			</ModalBody>

			<ModalFooter>
				<Column justifyContent="flex-start">
					{currentStep < lastStepIndex ? (
						<Button
							view="secondary"
							color="primary"
							disabled={steps[currentStep].isValid === false}
							onClick={() => setCurrentStep(s => s + 1)}
						>
							Next
						</Button>
					) : (
						<Button view="primary" disabled={isSaveDisabled} onClick={handleSave}>
							Save
						</Button>
					)}

					<Button view="empty" onClick={props.close}>
						Cancel
					</Button>
				</Column>
			</ModalFooter>
		</>
	);
};

export default ModalContent;
