import type { CmsCollectionType, CmsCollectionDataItem } from 'types/cms';
import { CMS } from 'admin/constants/actions';

export function setCollectionItems(payload: { collectionId: string; items: CmsCollectionDataItem[] }) {
	return { type: CMS.SET_ITEMS.FULFILLED, payload };
}

export function setCollections(payload: CmsCollectionType[]) {
	return { type: CMS.SET_COLLECTIONS.FULFILLED, payload };
}
