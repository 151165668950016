import React, { RefObject, useMemo } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBModel } from 'types/story';
import cmsUtils from 'common/utils/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import type { TreeComponentProps } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import { useClientSelector } from 'client/reducers';
import { selectStorySymbols } from 'client/reducers/story/selectors';
import { useListCollectionItems } from 'client/queries/cms/list-collection-items';
import { traverseTree } from 'client/components/common/BuildingBlocks/utils/traverse-tree';
import { processRepeatableComponents } from 'utils/cms/cms-repeatable-components-processor';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import css from './CmsRepeater.scss';

type Props = BBCommonProps;
type ReplicatorTreeContext = { traverseContext: TreeComponentProps['traverseContext'] };

/*
Main tasks:
[x] - editor mode
[x] - replicate in preview|published mode
[ ] - fetch data
[ ] - consume data in children

Current:
- fetch real data from database with react-query on client side app
- consume fetched data by repeated component
 */

/*
 todo: see this and see before/after in Excalidraw
 1. fetch collection data
    - request from Admin app (when in dashboard)
    - request from API (when in published mode)
 2. replicate children[0] n-times (same as I did with `processRepeatableComponents:processElement`)
    mb I even can simply reuse same function!!!
 3. call traverse tree to render children!!!
 4. BOOOOM!!! Now just need to make sure that I know from where each component and `generateElementCssString`
    will take the data from. Previously all was in Redux... But i wanted somehow isolate mb..
 */

/*
 Editor:
 - repeater with children rendered as it is... skip all the logic except data fetching
 Preview|Published:
 - repeater rendered without children at traverse tree
 - repeater fetches data from API
 - repeater replicates itself n-times
 - repeater return traverse tree with N-replicated repeaters
 - this time, traverse tree should render repeater as it is with a children
 - these repeaters should be marked as "nested" to avoid infinite loop
 */

// <CmsRepeaterEditable /> - fetch, render children as it is
// <CmsRepeaterReplicator /> - fetch, replicate, no DOM node, render traverse tree with N-replicated repeaters
// <CmsRepeaterReplica /> - DOM node, pure render,

const useData = ({ collectionId, pageSize }: { collectionId: string; pageSize: number }) => {
	// const __collection = mockData.collections.find(c => c.id === collectionId);
	// const __items = __collection ? mockData.items[__collection.id] : { items: [], total: 0 };

	// const organizationId = useClientSelector(state => state.story.story?.organizationId ?? 'unknown');
	// const collections = useListCollections({ organizationId });
	// const collection = useCollection({ collectionId });
	const items = useListCollectionItems({
		collectionId,
		pageSize,
		page: 0,
		orderBy: 'position',
		orderDir: 'asc',
	});

	// console.info('=== CmsRepeaterReplicator:fetch ===', {
	// 	organizationId,
	// 	collectionId,
	// 	collections,
	// 	collection,
	// 	items,
	// });
	// Editor|Preview - request and receive data from Admin app
	// ...

	// Published - request and receive data from API
	// ...

	const page = (items.data?.pageParams?.at(0) as number) ?? 0;

	return { collectionId, items: items.data?.pages[page] };
};

type RepeaterBodyProps = Pick<
	Props,
	'uiConfig' | 'stateAttrs' | 'containerRef' | 'parentStates' | 'children' | 'editableModeProps' | 'eventListeners'
>;

const RepeaterBody: React.FC<RepeaterBodyProps> = ({ editableModeProps, uiConfig, ...props }) => (
	<div
		{...uiConfig.nodeProps}
		{...props.stateAttrs}
		{...props.eventListeners}
		{...editableModeProps?.nodeProps}
		style={uiConfig.nodeProps?.style}
		className={cn(css.cmsRepeater, uiConfig.nodeProps.className, editableModeProps?.nodeProps?.className)}
		ref={props.containerRef as RefObject<HTMLDivElement>}
	>
		<ChildrenWithParentState states={props.parentStates}>{props.children}</ChildrenWithParentState>
	</div>
);

const CmsRepeaterEditable: React.FC<Props> = props => {
	// 1. fetch data (it simply must be available in for children in Redux, Context, etc.)
	// 2. render children as it is

	return (
		<RepeaterBody
			uiConfig={props.uiConfig}
			stateAttrs={props.stateAttrs}
			containerRef={props.containerRef}
			parentStates={props.parentStates}
			eventListeners={props.eventListeners}
			editableModeProps={props.editableModeProps}
		>
			{props.children}
		</RepeaterBody>
	);
};

const CmsRepeaterReplica: React.FC<Props> = props => {
	return (
		<RepeaterBody
			uiConfig={props.uiConfig}
			stateAttrs={props.stateAttrs}
			containerRef={props.containerRef}
			parentStates={props.parentStates}
			editableModeProps={props.editableModeProps}
		>
			{props.children}
		</RepeaterBody>
	);
};

const CmsRepeaterReplicator: React.FC<Props & ReplicatorTreeContext> = props => {
	// 1. fetch data
	// 2. replicate children[0] n-times
	// 3. render traverse tree with N-replicated repeaters

	const { collectionId, items } = useData({
		collectionId: props.uiConfig.componentProps.collectionId ?? '',
		pageSize: cmsUtils.getComponentProp(props, UNIFORM_PROPS.collectionPageSize),
	});
	const symbols = useClientSelector(selectStorySymbols);

	const replicatedTree = useMemo(() => {
		if (!collectionId || !items || !props.traverseContext) {
			return null;
		}

		console.info('=== CmsRepeaterReplicator:replicate ===', { collectionId, items, props });
		const { children, ...parentTreeContext } = props.traverseContext?.mapChildrenProps ?? {};

		const buildingBlockModel: BBModel = {
			_id: props._id,
			type: props.type,
			uiConfig: props.uiConfig,
			...('symbol' in props ? { symbol: props.symbol } : {}),
			children,
		};

		// todo: invoke again on data change (by init, filter, pagination...)
		const processResult = processRepeatableComponents({
			type: 'update-children',
			// data: Array.isArray(children) ? children : [],
			data: [{ ...buildingBlockModel, children: [buildingBlockModel] }],
			items: { [collectionId]: cmsUtils.transformItemsArrayToObject(items.items) },
			symbols,
		});

		// todo: invoke again on processResult or parentTreeContext change
		return traverseTree({
			tree: processResult.data[0].children as BBModel[],
			options: { ...props.traverseContext.options, isNested: true },
			parentTreeContext: {
				// todo: drop last index from every parent context field to omit logical Repeater parent
				path: parentTreeContext.path.split('.').slice(0, -2).join('.'), // "1.children.0{{.children.0}}"
				idPath: parentTreeContext.idPath.split('.').slice(0, -1).join('.'),
				parent: parentTreeContext.parent.slice(0, -1),
				cssParent: parentTreeContext.cssParent,
				isFFC: parentTreeContext.isFFC,
				parentIndexOffset: parseInt(parentTreeContext.path.split('.').at(-1) ?? '', 10) || 0,
			},
		});
		// todo: add deps, to re-run this effect when data or traverseContext changes
	}, [collectionId, items, symbols]);

	// console.info('=== CmsRepeaterReplicator:render ===', { data, props, replicatedTree });

	if (!replicatedTree) {
		// todo: if nothing to replicate, probably it's an "empty state"
		// I can return some placeholder, or RepeaterBody with some story or RepeaterPlaceholder
		return <span>Loading...</span>;
	}

	return replicatedTree;
};

// fixme: noticed that it does not get parent states, but it's children do
const CmsRepeater: React.FC<Props & ReplicatorTreeContext> = ({ traverseContext, ...props }) => {
	if (props.isEditableMode) {
		// fetch data, render children as it is
		return <CmsRepeaterEditable {...props} />;
	}

	if (traverseContext) {
		// fetch data, replicate, render traverse tree with N-replicated repeaters
		return <CmsRepeaterReplicator {...props} traverseContext={traverseContext} />;
	}

	// render itself with children as it is
	return <CmsRepeaterReplica {...props} />;
};

// todo: consider to wrap with enhancer only CmsRepeaterReplica and CmsRepeaterEditable
export default withCardTransitionContext(CmsRepeater);
