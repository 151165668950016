import React from 'react';
import type { BBCommonProps } from 'types/story';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import Swiper from 'swiper';
import type { Swiper as SwiperType } from 'swiper/types';
import {
	Navigation,
	Pagination,
	Autoplay,
	Mousewheel,
	EffectFade,
	EffectFlip,
	EffectCoverflow,
	Virtual,
	FreeMode,
} from 'swiper/modules';

import { attachRef } from 'utils/helpers';
import { useClientSelector } from 'client/reducers';
import { selectEditableElementNodeId } from 'client/reducers/editor/selectors';
import { SelectionHintEvEm } from 'client/components/common/SelectionHint/utils';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { AnimationEvEm } from 'client/components/common/BuildingBlocks/animation';
import Context from '../context';
import css from './SliderSlides.scss';

const isChildHidden = (child: React.ReactElement<BBCommonProps>) =>
	child.props.uiConfig.componentProps?.styles?.display === 'none';

const SliderSlides: React.FC<BBCommonProps> = props => {
	const { settings, paginationSettings, paginationRef, prevArrowRef, nextArrowRef } = React.useContext(Context);
	const { editableModeProps: EMP, stateAttrs, uiConfig, isEditableMode = false } = props;
	const swiperRef = React.useRef<Swiper | null>(null);
	const swiperContainerRef = React.useRef<HTMLDivElement | null>(null);
	const swiperSlidesRef = React.useRef<Array<React.RefObject<HTMLDivElement>>>([]);
	const selectedElementNodeId = useClientSelector(selectEditableElementNodeId);
	// store children string to reflect on changes made in children count or order
	const childrenIdString = React.Children.toArray(props.children)
		.map(child => (React.isValidElement<BBCommonProps>(child) && !isChildHidden(child) ? child.props._id : null))
		.join(',');

	const [isInitialized, setIsInitialized] = React.useState(false);

	React.useEffect(
		function init() {
			swiperRef.current = new Swiper(swiperContainerRef.current!, {
				...settings,
				navigation: { nextEl: nextArrowRef.current, prevEl: prevArrowRef.current },
				pagination: {
					el: paginationRef.current,
					clickable: true,
					...(typeof paginationSettings === 'object' ? paginationSettings : null),
				},
				lazyPreloadPrevNext: 1,
				mousewheel: {
					enabled: Boolean(settings.mousewheel),
					// preserve double swipe trigger using trackpad or mousewheel
					thresholdDelta: 20,
				},

				modules: [
					Navigation,
					Pagination,
					Autoplay,
					Mousewheel,
					Virtual,
					FreeMode,
					EffectFade,
					EffectFlip,
					EffectCoverflow,
				],
			});

			swiperSlidesRef.current.forEach(ref => ref.current?.classList.add(css.slide, 'swiper-slide'));

			setIsInitialized(true);
			return () => {
				swiperRef.current?.destroy();
				setIsInitialized(false);
			};
		},
		[settings, paginationSettings, childrenIdString, paginationRef, prevArrowRef, nextArrowRef, isEditableMode]
	);

	useTriggerInViewAnimation({
		isInitialized,
		settings,
		children: props.children,
		isEditableMode,
		swiperRef,
	});

	const isMultipleSlides = settings.effect === 'slide' && Number(settings.slidesPerView) > 1;

	React.useEffect(
		function slideToSelected() {
			const selectedElement =
				isEditableMode && selectedElementNodeId ? document.getElementById(selectedElementNodeId) : null;

			if (selectedElement && swiperContainerRef.current?.contains(selectedElement)) {
				const idx = swiperSlidesRef.current.findIndex(ref => ref.current?.contains(selectedElement));
				const hiddenSlidesIdxCorrection = swiperSlidesRef.current.reduce((acc, slideRef, slideIdx) => {
					return slideRef.current === null && slideIdx <= idx ? acc + 1 : acc;
				}, 0);

				const matchSlide = idx !== -1;
				const shouldSlide = isMultipleSlides
					? matchSlide && !isSlideInView(swiperContainerRef.current, selectedElement)
					: matchSlide;

				if (shouldSlide) {
					swiperRef.current?.enable();
					swiperRef.current?.slideTo(idx - hiddenSlidesIdxCorrection, 0, false);
					swiperRef.current?.disable();
					SelectionHintEvEm.emit('forceUpdate');
				}
			}
		},
		[isEditableMode, selectedElementNodeId, isMultipleSlides]
	);

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.slides, uiConfig.nodeProps.className, EMP?.nodeProps?.className, 'swiper-container')}
			ref={attachRef([props.containerRef!, swiperContainerRef])}
		>
			<div className={cn('swiper-wrapper')}>
				{React.Children.map(props.children, (slide, idx) => {
					// store slide ref
					swiperSlidesRef.current[idx] = React.createRef<HTMLDivElement>();

					if (React.isValidElement<BBCommonProps>(slide)) {
						if (isChildHidden(slide)) {
							// render to portal in edit mode to keep the slide in the DOM to show/hide from Layers
							return isEditableMode ? createPortal(<span>{slide}</span>, document.body) : null;
						}

						return (
							<div key={`slide-${slide.props._id}`} ref={swiperSlidesRef.current[idx]}>
								{slide}
							</div>
						);
					}

					return null;
				})}
			</div>
		</div>
	);
};

function isSlideInView(container: HTMLElement, element: HTMLElement) {
	const containerRect = container.getBoundingClientRect();
	const elementRect = element.getBoundingClientRect();

	return (
		elementRect.top >= containerRect.top &&
		elementRect.bottom <= containerRect.bottom &&
		elementRect.left >= containerRect.left &&
		elementRect.right <= containerRect.right
	);
}

function walkThroughChildren(children: React.ReactNode, callback: (child: React.ReactElement<BBCommonProps>) => void) {
	React.Children.forEach(children, child => {
		if (React.isValidElement<BBCommonProps>(child)) {
			callback(child);
			walkThroughChildren(child.props.children, callback);
		}
	});
}

interface UseTriggerInViewAnimationParams {
	isInitialized: boolean;
	settings: SwiperType['params'];
	children: React.ReactNode;
	isEditableMode: boolean;
	swiperRef: React.MutableRefObject<Swiper | null>;
}
function useTriggerInViewAnimation(props: UseTriggerInViewAnimationParams) {
	const { isInitialized, isEditableMode, swiperRef, settings, children } = props;
	const childrenRef = React.useRef(children);
	childrenRef.current = children;

	React.useEffect(() => {
		const prevInView = new Set<number>();

		if (!isEditableMode && swiperRef.current) {
			swiperRef.current.on('init', onInit);
			swiperRef.current.on('realIndexChange', onRealIndexChange);
		}

		function onInit(swiper: SwiperType) {
			const slidesPerView =
				settings.slidesPerView === 'auto' ? swiper.slidesPerViewDynamic() : settings.slidesPerView ?? 1;

			prevInView.clear();
			Array.from({ length: slidesPerView }).forEach((_, idx) => prevInView.add(idx));
		}

		function onRealIndexChange({ realIndex: startIndex, slidesPerViewDynamic }: SwiperType) {
			const slidesPerView =
				settings.slidesPerView === 'auto' ? slidesPerViewDynamic() : settings.slidesPerView ?? 1;

			const childrenArray = React.Children.toArray(childrenRef.current);
			const inView: number[] = [];
			let slidesProcessed = 0;

			while (slidesProcessed < slidesPerView) {
				const index = (startIndex + slidesProcessed) % childrenArray.length;
				const slide = childrenArray[index];

				if (React.isValidElement<BBCommonProps>(slide)) {
					const wasInView = prevInView.has(index);

					if (!wasInView) {
						AnimationEvEm.emit('playback', { action: 'onSlideChangeStart', _id: slide.props._id });

						walkThroughChildren(slide.props.children, child => {
							AnimationEvEm.emit('playback', { action: 'onSlideChangeStart', _id: child.props._id });
						});
					}
					inView.push(index);
				}
				slidesProcessed += 1;
			}

			prevInView.clear();
			inView.forEach(idx => prevInView.add(idx));
		}
	}, [isInitialized, settings.slidesPerView, isEditableMode, swiperRef]);

	return null;
}

export default withCardTransitionContext(SliderSlides);
