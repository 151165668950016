import React from 'react';
import { Field } from 'redux-form';

import { Context as ThemeContext } from 'admin/components/common/Theme';
import { Checkbox, TextField } from 'admin/components/common/Form';
import { fields } from 'admin/components/pages/Story/Flow/EditCardModal/Form';
import { useAdminSelector } from 'src/routes/admin/reducers';
import { selectStoryFacade } from 'src/routes/admin/reducers/story-editor/selectors';
import css from './EditCardModal.scss';

type Props = {};

const RedirectUrl: React.FC<Props> = props => {
	const context = React.useContext(ThemeContext);
	const storyFacade = useAdminSelector(selectStoryFacade);
	const options =
		storyFacade.settings.integrations?.urlParams?.params?.map(param => ({
			value: param,
			label: param,
		})) ?? [];

	if (options.length === 0) {
		options.push({ value: '', label: 'No URL params available' });
	}

	return (
		<div className={css.redirectUrl}>
			<div className={css.redirectUrlWrap}>
				<Field
					name={fields.redirectUrl}
					component={TextField}
					isRequired
					label="Redirect URL"
					props={{
						theme: context?.theme,
						isLabelUppercase: false,
						isRequired: true,
					}}
				/>
			</div>
			<div className={css.redirectUrlWrap}>
				<Field
					name={fields.includeUrlParams}
					component={Checkbox}
					isRequired
					label={{ children: 'Include URL params' }}
					props={{
						theme: context?.theme,
						labelPosition: 'right',
						isLabelUppercase: false,
						isRequired: true,
					}}
				/>
			</div>
		</div>
	);
};

export default RedirectUrl;
