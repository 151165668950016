/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { get, invoke, capitalize } from 'lodash';
import { createPortal } from 'react-dom';

import { OTHER_PROPS } from 'common/constants/component-props';
import {
	cutURLMethod,
	getSourceType,
	parseImgSrc,
	PICTURES_FILE_TYPES,
	replaceUrlHostname,
	VIDEO_FILE_TYPES,
} from 'common/utils/assets';
import { COMPONENT_TYPE } from 'common/constants';
import VideoPlayer from 'common/components/VideoPlayer';
import { getVimeoId, getYoutubeId } from 'common/components/VideoPlayer/helpers';
import ImageUploadIcon from 'common/components/Icon/UploadImage';
import ChangeIcon from 'common/components/Icon/Change';
import Loader from 'common/components/Loader';
import { getClientFrame } from 'common/utils/iframe-tunnel';
import { useAdminSelector } from 'admin/reducers';
import { selectEditableStoryType, selectStorycardsDomain } from 'admin/reducers/story-editor/selectors';
import Text from 'admin/components/common/Text';
import { Icon, LAYER_ICONS } from 'admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form/Errors';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import Divider from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Divider';
import { Label } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Label';

import ImagePreview from './ImagePreview';
import css from './MediaField.scss';

const LottiePreview = React.lazy(() => import('./LottiePreview'));

type Props = {
	input: Omit<ReduxFieldInputTypes, 'value'> & { value: string };
	meta: ReduxFieldMetaTypes;
	className?: string;
	showErrorsOn?: string;
	showLink?: boolean;
	fieldRef?: React.MutableRefObject<HTMLInputElement | null>;
	globalError?: boolean;
	fieldOnly?: boolean;
	customData?: {
		tint?: string;
		isRemovable?: boolean;
		fallbackValue?: string;
		fileTypes?: string[];
		minimized?: true;
	};
	eventListeners: {
		onClear?: () => void;
		onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLElement, MouseEvent>) => void;
		onUpload?: (p: { name: string; asset: string }) => void;
	};
};

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const MAX_VIDEO_FILE_SIZE = 1024 * 1024 * 15;
const videoFieldNames = new Set<string>([OTHER_PROPS.video.videoSrc, OTHER_PROPS.card.backgroundVideo]);
const videoSourceTypes = new Set<ReturnType<typeof getSourceType>>(['video', 'vimeo', 'youtube', 'hls']);

const MediaFieldComponent = (props: Props) => {
	const storycardsDomain = useAdminSelector(selectStorycardsDomain);
	const storyType = useAdminSelector(selectEditableStoryType);
	const { className, input, meta, showErrorsOn, customData } = props;
	const [currentFile, setCurrentFile] = useState<File | null>(null);
	const [currentFileSrc, setCurrentFileSrc] = useState<string | null>(null);
	const [imageSize, setImageSize] = useState<null | { w: number; h: number }>(null);
	const [isBusy, setIsBusy] = useState(false);
	const [link, setLink] = useState('');
	const [customError, setCustomError] = useState<string | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const id = `${meta.form}.${input.name}`;
	const linkInputId = `pf-link-${id}`;

	let currentValue = input.value === 'none' && customData?.fallbackValue ? customData?.fallbackValue : input.value;

	const hasValue = !!currentValue && currentValue !== 'none';

	// mediaField value might be saved with url(value) - for example backgroundImage property
	currentValue = cutURLMethod(currentValue);

	const fileTypes = props.customData?.fileTypes || PICTURES_FILE_TYPES;
	const fileUrl = replaceUrlHostname(
		(currentValue || currentFileSrc || '').replace(/"/g, '').replace(/^\.\//, '/'),
		storycardsDomain
	);
	const sourceType = getSourceType(currentValue);
	const property = input.name.split('.').pop() ?? '';
	const isLottieField = property === OTHER_PROPS.lottie.ltSrc;
	const isVideoSrcField = videoFieldNames.has(property);
	const isVideoFile = videoSourceTypes.has(sourceType);
	const isPreviewAvailable = ((link || currentFile?.name) && hasValue) || hasValue;
	const mediaLabel = isLottieField
		? 'file'
		: fileTypes === VIDEO_FILE_TYPES
			? 'video'
			: fileTypes === PICTURES_FILE_TYPES
				? 'image'
				: 'media';
	const copyrightNote = `Copy of this ${mediaLabel} will be created in your story. Make sure you have the rights to use it.`;

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		try {
			const file = e.target.files?.[0];
			if (!file) return;

			const maxSize = getSourceType(file.name) === 'video' ? MAX_VIDEO_FILE_SIZE : MAX_FILE_SIZE;
			if (file.size > maxSize) {
				const errMsg = `File size exceeds ${Math.round(maxSize / (1024 * 1024))}Mb`;
				if (props.globalError) throw new AdminError(errMsg, { duration: 3 });
				else setCustomError(errMsg);
				return;
			}

			const reader = new FileReader();
			setCustomError(null);
			setIsBusy(true);

			reader.onload = (event: ProgressEvent<FileReader>): void => {
				const target = event.target as FileReader;
				if (target && target.result) setCurrentFileSrc(target.result.toString());
			};
			reader.readAsDataURL(file);

			setCurrentFile(file);

			// Make auto upload right after file selection (only for now, in the future upload should be handled by story.save())
			const response = await invoke(props, 'eventListeners.onUpload', {
				name: input.name,
				asset: file,
			});
			setCurrentFileSrc(response.asset);
			input.onChange(response.asset);
		} catch (err) {
			const errMsg = (err as Error)?.message;
			if (props.globalError) throw new AdminError(errMsg, { duration: 3 });
			else setCustomError(errMsg);
		} finally {
			setIsBusy(false);
		}
	};

	const onClearBtnClick = () => {
		props.eventListeners.onUpload?.({ name: input.name, asset: '' });
		input.onChange('');
		setCurrentFile(null);
		setCurrentFileSrc(null);
		setImageSize(null);
		if (inputRef.current) inputRef.current.value = '';
	};

	const onImgLinkInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setLink(e.currentTarget.value);
	};

	const onLinkBtnClick = async () => {
		setIsBusy(true);

		let isValidType = true;
		let hasError = false;

		function loadImage(url) {
			const img = document.createElement('img');
			return new Promise<HTMLImageElement>((res, rej) => {
				img.onload = () => res(img);
				img.onerror = () => rej(new Error('Image URL is invalid.'));
				img.src = url;
			});
		}

		try {
			switch (getSourceType(link)) {
				case 'image':
					await loadImage(link);
					break;
				case 'youtube':
					if (!getYoutubeId(link)) throw new Error('Invalid url');
					break;
				case 'vimeo':
					if (!getVimeoId(link)) throw new Error('Invalid url');
					break;
				case 'uri':
					throw new Error("Data URI isn't supported");
				case 'video':
					if (!RegExp(fileTypes.join('|'), 'i').test(link)) throw new Error('Invalid file type');
					break;
				default:
					throw new Error('Invalid url');
			}
			isValidType = true;
			if (customError) setCustomError(null);
		} catch (e) {
			setCustomError((e as any)?.message);
			hasError = true;
		}

		setIsBusy(false);

		if (hasError) return;

		if (!isValidType) {
			setCustomError('Invalid file format');
			return;
		}

		await invoke(props, 'eventListeners.onUpload', {
			name: input.name,
			asset: link,
		});

		input.onChange(link);
	};

	useEffect(() => {
		if (getSourceType(fileUrl) === 'image') {
			const src = fileUrl.replace('"', '');
			const { originalHeight: h, originalWidth: w } = parseImgSrc(src, { storycardsDomain });
			if (w && h) {
				// Do not load image again if natural size is already defined in search params
				setImageSize({ w, h });
			} else {
				const img = new Image();
				img.onload = () => setImageSize({ w: img.width, h: img.height });
				img.src = src;
			}
		}
	}, [fileUrl, storycardsDomain]);

	const FileInput = (
		<input
			ref={ref => {
				inputRef.current = ref;
				// eslint-disable-next-line no-param-reassign
				if (props.fieldRef) props.fieldRef.current = ref;
			}}
			name={input.name}
			id={id}
			type="file"
			accept={fileTypes.join(',')}
			onChange={onFileChange}
			onClick={e => {
				if (e.altKey) {
					props.eventListeners.onClick?.(e);
					e.preventDefault();
				}
			}}
		/>
	);

	// This case currently is used only when attempting to load image via toolbar at client side
	if (props.fieldOnly) {
		const { frame } = getClientFrame();
		return (
			<>
				{FileInput}
				{isBusy &&
					frame &&
					createPortal(<Loader fullFrame bgColor="rgba(0, 0, 0, 0.38)" />, frame.parentElement!)}
			</>
		);
	}

	const onUploadClick = (e: React.MouseEvent<HTMLLabelElement>) => {
		if (isVideoSrcField) {
			e.preventDefault();
			props.eventListeners.onClick?.(e);
		}
	};

	return (
		<div
			className={cn(css.pfMedia, className, {
				[css.hasMedia]: isPreviewAvailable,
				[css.busy]: isBusy,
				[css.minimized]: customData?.minimized,
			})}
		>
			{/* Image preview */}
			{isPreviewAvailable &&
				(isVideoFile ? (
					<div className={css.pfMediaVideoPreview}>
						{isVideoFile && (
							<VideoPlayer src={fileUrl} muted loop={false} autoplay={false} controls={false} />
						)}
					</div>
				) : isLottieField ? (
					<React.Suspense fallback={null}>
						<LottiePreview src={fileUrl} />
					</React.Suspense>
				) : (
					<ImagePreview src={fileUrl} />
				))}

			{/* Preloader */}
			{isBusy && (
				<Loader
					fullFrame
					bgColor="rgba(0, 0, 0, 0)"
					size={customData?.minimized ? 'small' : 'medium'}
					classNameRoot={css.preloader}
				/>
			)}

			{/* Custom errors */}
			<Errors className={css.errors} show={!!customError}>
				{customError}
			</Errors>

			{/* Tint */}
			<div className={css.pfMediaImgTint} style={{ backgroundColor: get(customData, 'tint') }} />

			{isPreviewAvailable ? (
				<>
					{/* Preview UI */}
					<div className={css.pfMediaImgShading} />
					<div className={css.pfMediaImgInfo}>
						{sourceType !== 'hls' && (
							<Text
								className={css.fileName}
								size="label"
								text="Download original"
								tag="a"
								href={fileUrl.split('?').shift()}
								target="_blank"
								rel="noreferrer noopener"
							/>
						)}
						{sourceType === 'image' && (
							<div className={css.fileSize}>
								<Text size={Text.size.footnote} text={`${imageSize?.w}`} tag="div" />
								<Text className={css.fileSizeX} size={Text.size.footnote} text="+" tag="div" />
								<Text size={Text.size.footnote} text={`${imageSize?.h}`} tag="div" />
							</div>
						)}
					</div>
					{get(customData, 'isRemovable') && (
						<button className={css.clearBtn} type="button" onClick={onClearBtnClick}>
							<Icon type="x-rounded" width={24} />
						</button>
					)}
					<label htmlFor={id} className={css.changeImageBtn} onClick={onUploadClick}>
						<ChangeIcon />
						<Text
							className={css.fileName}
							size={Text.size.label}
							text={`Change ${capitalize(mediaLabel)}`}
							tag="div"
						/>
					</label>
				</>
			) : (
				<>
					{/* Upload image */}

					<label htmlFor={id} className={css.uploadImageBtn} onClick={onUploadClick}>
						{isLottieField ? (
							<Icon type={LAYER_ICONS[COMPONENT_TYPE.LOTTIE]} width={50} />
						) : (
							<ImageUploadIcon width="40px" height="50px" />
						)}
						<Text
							className={css.label}
							size={Text.size.label}
							text={`Upload ${capitalize(mediaLabel)}`}
							tag="div"
						/>
						<Text
							className={css.subLabel}
							size={Text.size.footnote}
							text={fileTypes
								.map(type => type.replace('.', ''))
								.join(', ')
								.toUpperCase()}
							tag="div"
						/>
					</label>

					{/* Link & Copyright  */}
					{props.showLink && (
						<div className={css.content}>
							<Divider />
							<Label component="label" htmlFor={linkInputId}>
								From Link
							</Label>
							<div className={css.imgLinkInputWrap}>
								<input
									id={linkInputId}
									value={link}
									onChange={onImgLinkInputChange}
									placeholder="Paste URL…"
									type="text"
									onKeyUp={e => {
										if (e.key === 'Enter') onLinkBtnClick();
									}}
								/>
								{link && (
									<button onClick={onLinkBtnClick} type="button">
										<Icon type="search" />
									</button>
								)}
							</div>
							<Text
								className={css.copyrightLabel}
								size={Text.size.footnote}
								text={copyrightNote}
								tag="div"
							/>
						</div>
					)}
				</>
			)}

			{FileInput}

			{showErrorsOn && <Errors show={meta[showErrorsOn]}>{meta.error}</Errors>}
		</div>
	);
};

export const MediaField = (props: Props) => {
	return (
		<MediaFieldComponent
			// re-initialize component
			key={`${props.meta.form}.${props.input.name}`}
			showErrorsOn="touched"
			{...props}
		/>
	);
};

/*
 Example:
	<Field
		name="email"
		component={MediaField}
		className="--custom-class"
		eventListeners: {
			onUpload: () => {},
			onCLear: () => {},
		}
	/>
*/
