import { findIndex, get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import type { CardData, StorySettingsType } from 'types/story';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import t from 'common/utils/translate';
import { CARD_TYPE, CountType } from 'common/constants';
import { StoryFacade } from 'common/utils/facades/story-facade';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import { updateEditableStory } from 'admin/actions/story/update-editable-story';
import { MODAL } from 'admin/constants/common';
import { Content, EditCardModalType } from './Content';
import Form, { Values } from './Form';
import css from './EditCardModal.scss';

export type EditCardModalData = {
	stepId: string;
	cardId: string;
	onSave: () => void;
	onDiscard: () => void;
};

const getEditType = (card: CardData, storySettings: StorySettingsType) => {
	switch (card.type) {
		case CARD_TYPE.NAVIGATION:
			if (storySettings.cards?.[card._id]?.countType === CountType.character) {
				return EditCardModalType.editCharacterNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.score) {
				return EditCardModalType.editScoreNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.urlParam) {
				return EditCardModalType.editUrlParamsNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.redirect) {
				return EditCardModalType.editRedirectNav;
			}
			return EditCardModalType.editRateNav;

		default:
			return EditCardModalType.default;
	}
};

const getTitle = (editType: EditCardModalType) => {
	switch (editType) {
		case EditCardModalType.editCharacterNav:
			return 'Edit character events';
		case EditCardModalType.editScoreNav:
			return 'Edit score events';
		default:
			return t('story.editCardModal.title');
	}
};

const EditCardModal: React.FC<ModalManagerProvidedProps<MODAL.EDIT_CARD>> = props => {
	const dispatch = useAdminDispatch();
	const version = useAdminSelector(state => state.version.current);
	const story = useAdminSelector(selectEditableStory)!;
	const { open, data, close } = props;
	const { onDiscard, onSave } = data;

	const onSaveHandler = useCallback(() => {
		onSave();
		close();
	}, [onSave, close]);

	const onDiscardHandler = useCallback(() => {
		onDiscard();
		close();
	}, [onDiscard, close]);

	const cardPath = useMemo(() => {
		if (!data.stepId || !data.cardId) return '';
		const { steps } = new StoryFacade(story, version);
		const stepIndex = findIndex(steps, s => s._id === data.stepId);
		const cardIndex = findIndex(steps[stepIndex].cards, c => c._id === data.cardId);
		return `storyVersions.${version}.data.steps.${stepIndex}.cards.${cardIndex}`;
	}, [data.stepId, data.cardId, story, version]);

	const card: CardData | undefined = get(story, cardPath);

	if (!card) {
		return null;
	}

	const editType = getEditType(card, story.storyVersions[version].settings);
	const onSubmit = (values: Values) => {
		dispatch(
			updateEditableStory({
				data: values,
				path: cardPath,
				updateForm: true,
				isVersionPath: false,
			})
		);
	};

	return (
		<Modal
			title={<Text className={css.title} size={Text.size.paragraph} text={getTitle(editType)} />}
			open={open}
			destroyOnClose
			width="600px"
			className={css.modal}
			maskColor="black"
			theme="dark"
			onCancel={data.onDiscard}
		>
			<Form initialValues={props.open ? card : undefined} onSubmit={onSubmit} onSubmitSuccess={onSaveHandler}>
				{form =>
					form?.formData?._id ? (
						<Content onDiscard={onDiscardHandler} editType={editType} form={form} />
					) : null
				}
			</Form>
		</Modal>
	);
};

export default EditCardModal;
