import type { DictionaryType } from 'types/story';
import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { getText } from 'utils/dictionary';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Fields/TextField/TextField';
import { FieldBBType } from 'client/components/common/BuildingBlocks/Fields/constants';

type Props = {
	dictionary?: DictionaryType;
};

export const SEARCH_FIELD = ({ dictionary = {} }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SEARCH_FIELD;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},

					componentProps: {
						[UNIFORM_PROPS.fieldType]: FieldBBType.search,
						[UNIFORM_PROPS.fieldPlaceholder]: getText(dictionary, type, 'placeholder.text'),
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.field.style.text.phColor]: 'rgba(0, 0, 0, 0.54)',
								},
							},
						},
						other: {},
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
				},
				children: [],
			};
		},
	};
};
