import type { CmsCollectionDataItem, ListCollectionItemsInput } from 'types/cms';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAdminDispatch } from 'admin/reducers';
import { setCollectionItems } from 'admin/actions/cms';
import cmsUtils from 'common/utils/cms';
import { location } from 'common/utils/url-helper';
import { QUERY_KEYS } from '../constants';

const PAGE_SIZE = cmsUtils.defaultProps.collectionPageSize;

const INITIAL_PAGE = 0;

interface ListCollectionItemsQueryFnInput extends ListCollectionItemsInput {
	dispatch: ReturnType<typeof useAdminDispatch>;
}

interface ListCollectionItemsOutput {
	items: CmsCollectionDataItem[];
	total: number;
}

export const listCollectionItemsQueryFn = async (params: ListCollectionItemsQueryFnInput) => {
	const { collectionId, pageSize, page, filter, orderDir, orderBy } = params;

	const { cms } = await (location.client.isPreview ? import('admin/resources') : import('admin/resources'));

	const response = await cms.get.collectionItems
		.params({ collectionId })
		.send<ApiV2Response<ListCollectionItemsOutput>>({
			pageSize,
			page,
			orderBy,
			orderDir,
			...(filter ? { filters: cmsUtils.encodeFilter(filter) } : null),
		});

	const result = response.body ?? { items: [], total: 0 };

	params.dispatch(setCollectionItems({ collectionId: params.collectionId, items: result.items }));

	return result;
};

export const listCollectionItemsQueryKey = ({ collectionId, ...rest }: ListCollectionItemsInput) => [
	QUERY_KEYS.LIST_CMS_COLLECTION_ITEMS,
	collectionId,
	rest,
];

export const useListCollectionItems = (params: ListCollectionItemsInput) => {
	const dispatch = useAdminDispatch();

	return useInfiniteQuery({
		queryKey: listCollectionItemsQueryKey(params),
		queryFn: async ({ pageParam }) =>
			listCollectionItemsQueryFn({
				collectionId: params.collectionId,
				page: pageParam ?? INITIAL_PAGE,
				pageSize: params.pageSize ?? PAGE_SIZE,
				filter: params.filter,
				orderDir: params.orderDir,
				orderBy: params.orderBy,
				dispatch,
			}),
		initialPageParam: params.page ?? INITIAL_PAGE,
		getNextPageParam: (lastPage, pages) => {
			return pages[0].total > pages.flatMap(page => page.items).length ? pages.length : undefined;
		},
		enabled: !!params.collectionId,
	});
};
