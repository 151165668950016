import { BBModel, StorySymbols } from 'types/story';
import { CmsCollectionDataItem, CollectionPaginationType } from 'types/cms';
import type { CmsChangePageEvent, CmsChangeFilterEvent } from 'common/utils/cms/cms-event-emitter';
import cms from 'common/utils/cms';
import { detachSymbol } from 'common/utils/blocks/get-symbol-model';
import { UNIFORM_PROPS } from 'common/constants/component-props';

interface HandleUpdateCommonParams {
	element: BBModel;
	collectionId: string;
	collectionData: CmsCollectionDataItem[];
	symbols: StorySymbols;
}

interface HandleUpdateInitParams extends HandleUpdateCommonParams {
	type: 'init';
}

interface HandleUpdatePageParams extends HandleUpdateCommonParams {
	type: 'page';
	pagination: CmsChangePageEvent['data'];
}

interface HandleUpdateFilterParams extends HandleUpdateCommonParams {
	type: 'filter';
	filter: CmsChangeFilterEvent['data'];
}

type HandleUpdateParams = HandleUpdateInitParams | HandleUpdatePageParams | HandleUpdateFilterParams;

type RepeatableComponentState = {
	page: number;
	pageSize: number;
	pageMax?: number;
	originalComponent: BBModel;
	duplicatesCount: number;
	filter?: CmsChangeFilterEvent['data'];
	// page - classic pagination | infinite - infinite scroll
	strategy: 'page' | 'infinite';
};

type State = {
	[collectionId: string]: {
		[originalComponentId: string]: RepeatableComponentState;
	};
};

type InitializeStateParams = {
	element: BBModel;
	collectionId: string;
	pageSize: number;
	symbols: StorySymbols;
	pageMax?: number;
};

type ChangePageParams = {
	page: CmsChangePageEvent['data']['page'];
	totalItems: number;
};

/**
 * Manager for repeatable components pagination and filtering state
 */
class CmsRepeatableStateManager {
	// An object that stores state for each original repeatable component (page, filter, etc.)
	private state: State = {};

	// A Map that stores the origin of elements by mapping element IDs to their original element IDs.
	originMap = new Map</* current component id */ string, /* original component id */ string>();

	handleUpdate(props: HandleUpdateParams) {
		const { element, collectionId, symbols, type } = props;
		const currentState = this.getState({
			collectionId,
			originalComponentId: this.originMap.get(element._id) ?? '',
		});

		const collectionPageSize = cms.getComponentProp(element, UNIFORM_PROPS.collectionPageSize);
		const collectionPagination = cms.getComponentProp(element, UNIFORM_PROPS.collectionPagination);

		const isPaginated = collectionPageSize > 0 && currentState !== undefined;

		const result = {
			// next data slice
			dataSlice: [] as CmsCollectionDataItem[],
			// number of current items to delete in order to add new ones
			deleteCount: isPaginated ? currentState?.pageSize : 1,
			// repeatable component state
			state: currentState,
		};

		const filter = 'filter' in props ? props.filter : currentState?.filter;
		const collectionData = filter ? filterCollectionData(props.collectionData, filter) : props.collectionData;

		switch (type) {
			// Create new state
			case 'init': {
				const pageSize = collectionPageSize;

				const newState = this.initializeState({
					element,
					collectionId,
					pageSize,
					symbols,
					pageMax: getPageMax(pageSize, collectionData),
				});
				const dataSlice = this.getDataSlice(collectionData, newState);

				this.changeDuplicatesCount(newState, dataSlice.length);

				result.dataSlice = dataSlice;
				result.state = newState;
				break;
			}

			// Update filter state and reset pagination
			case 'filter': {
				if (!isPaginated) {
					break;
				}

				// Skip if not refers to the same original component
				if (props.filter.originalComponentId !== currentState?.originalComponent._id) {
					break;
				}

				// const filteredCollectionData = filterCollectionData(collectionData, props.filter);

				currentState.page = 1;
				currentState.pageMax = getPageMax(currentState.pageSize, collectionData);
				currentState.filter = props.filter;

				const dataSlice = this.getDataSlice(collectionData, currentState);
				this.changeDuplicatesCount(currentState, dataSlice.length);

				result.dataSlice = dataSlice;
				result.state = currentState;

				window.__storyDebug?.(result);
				break;
			}

			// Update page state including filter
			case 'page': {
				if (!isPaginated) {
					break;
				}

				// Skip if not refers to the same original component
				if (props.pagination.originalComponentId !== currentState?.originalComponent._id) {
					break;
				}

				const updatedState = this.changePage(currentState, {
					page: props.pagination.page,
					totalItems: collectionData.length,
				});
				const dataSlice = this.getDataSlice(collectionData, updatedState);
				this.changeDuplicatesCount(updatedState, dataSlice.length);

				result.dataSlice = dataSlice;
				result.state = updatedState;

				window.__storyDebug?.(result);
				break;
			}

			default:
				break;
		}

		return result;
	}

	private getState({ collectionId, originalComponentId }): RepeatableComponentState | undefined {
		return this.state[collectionId]?.[originalComponentId];
	}

	private initializeState(props: InitializeStateParams) {
		const { element: originalComponent } = detachSymbol(props.element, props.symbols);
		// Why `detachSymbol`?
		// Due to the complexity and lack of real necessity, it was decided to simplify the logic and transform
		// duplicated elements into plain blocks

		this.state[props.collectionId] = {
			...this.state[props.collectionId],
			[originalComponent._id]: {
				page: 1,
				pageSize: props.pageSize ?? cms.defaultProps.collectionPageSize,
				pageMax: props.pageMax,
				duplicatesCount: 0,
				originalComponent,
				strategy: 'page',
			},
		};

		return this.getState({ collectionId: props.collectionId, originalComponentId: originalComponent._id })!;
	}

	// Mutates state object
	private changePage(draftState: RepeatableComponentState, props: ChangePageParams) {
		const pageMax = Math.ceil(props.totalItems / draftState.pageSize);
		let nextPage: number;

		switch (props.page) {
			case 'next':
				nextPage = draftState.page + 1;
				break;
			case 'prev':
				nextPage = draftState.page - 1;
				break;
			default:
				nextPage = props.page;
				break;
		}

		if (nextPage < 1 || nextPage > pageMax || Number.isNaN(nextPage)) {
			throw new Error(`Invalid page number ${nextPage}/${pageMax}`);
		}

		draftState.page = nextPage;

		return draftState;
	}

	// Mutates state object
	private changeDuplicatesCount(draftState: RepeatableComponentState, duplicatesCount: number = 0) {
		draftState.duplicatesCount = duplicatesCount;
		return draftState;
	}

	private getDataSlice(collectionData: CmsCollectionDataItem[], state: RepeatableComponentState) {
		return Array.isArray(collectionData)
			? collectionData.slice(state.pageSize * (state.page - 1), state.pageSize * state.page)
			: [];
	}
}

// todo: change pageMax to real value from backend, collectionData.length is not always correct
function getPageMax(pageSize: number, collectionData: CmsCollectionDataItem[]) {
	return pageSize > 0 ? Math.ceil(collectionData.length / pageSize) : undefined;
}

// todo: collectionData is local data. It might be right place to request data from the server
function filterCollectionData(collectionData: CmsCollectionDataItem[], filter: CmsChangeFilterEvent['data']) {
	return collectionData.filter(item => {
		const { condition, field } = filter;
		const fieldValue = `${item[field]}`.toLowerCase();
		const value = `${filter.value}`.toLowerCase();

		switch (condition) {
			case 'equal':
				return fieldValue === value;
			case 'startsWith':
				return fieldValue.startsWith(value);
			case 'endsWith':
				return fieldValue.endsWith(value);
			case 'contains':
				return fieldValue.includes(value);
			default:
				return true;
		}
	});
}

export default new CmsRepeatableStateManager();
