export enum QUERY_KEYS {
	// video
	PAGINATE_VIDEOS = 'paginate-videos',
	VIDEO_STATS = 'video-stats',
	VIDEO_ORIGINAL_FILE_LINK = 'video-original-file-link',
	VIDEO_ITEM = 'video-item',
	VIDEOS_USAGE = 'videos-usage',

	// story
	GET_STORY_AUDIT_LOGS = 'get-story-audit-logs',

	// collection
	GET_CMS_COLLECTION = 'GET_CMS_COLLECTION',
	LIST_CMS_COLLECTIONS = 'LIST_CMS_COLLECTIONS',
	LIST_CMS_COLLECTION_ITEMS = 'LIST_CMS_COLLECTION_ITEMS',
}
