import React, { ComponentType, createRef, MouseEventHandler, MutableRefObject } from 'react';
import _ from 'lodash';
import produce from 'immer';
import type { BBCommonProps } from 'types/story';
import { location } from 'common/utils/url-helper';
import { ANIMATION_STATE, ANIMATION_TRIGGER, COMPONENT_TYPE } from 'common/constants';
import { ANIMATION_HOC_SENDER_ID, ANIMATION_SETTINGS_SENDER_ID } from 'client/constants/common';
import {
	AnimationData,
	parseAnimation,
	AnimationEvEm,
	rafTicker,
	AnimationPlaybackEvent,
	AnimationSettingsEvent,
} from 'client/components/common/BuildingBlocks/animation';
import { isEditableMode } from 'client/components/common/BuildingBlocks/utils/common';

type WithAnimationProvidedProps = {
	isEmbed: boolean;
};

interface WithAnimationProps extends Omit<BBCommonProps, keyof WithAnimationProvidedProps> {
	animation?: Record<string, any>;
	Component: ComponentType<BBCommonProps>;
}

type AnimationDataRecord = Record<string, AnimationData>;

type ParsedAnimationDataRecord = Record<string, ReturnType<typeof parseAnimation>>;

type WithAnimationState = {
	currentAnimation: string;
	animationState: Record<string, (typeof ANIMATION_STATE)[keyof typeof ANIMATION_STATE]>;
	animationDataRaw?: AnimationDataRecord;
	animationData: ParsedAnimationDataRecord;
};

class WithAnimation extends React.Component<WithAnimationProps, WithAnimationState> {
	static displayName: string;

	static defaultProps = {
		animation: null,
	};

	static getDerivedStateFromProps(props: WithAnimationProps, state: WithAnimationState) {
		const animation = _.get(props, 'uiConfig.componentProps.animation') as AnimationDataRecord | undefined;
		if (animation !== state.animationDataRaw) {
			return {
				animationDataRaw: animation,
				animationData: _.reduce(
					animation,
					(acc, current: AnimationData, key: string) => {
						acc[key] = parseAnimation(current, `animation-${key}-${props._id}`);
						return acc;
					},
					{}
				),
			};
		}
		return null;
	}

	containerRef = createRef<HTMLElement>();

	delayedEventTimeouts: any;

	isEmbed: boolean;

	constructor(props: WithAnimationProps) {
		super(props);

		this.delayedEventTimeouts = {};

		const animationDataRaw = _.get(props, 'uiConfig.componentProps.animation') as AnimationDataRecord | undefined;

		const animationData = _.reduce(
			animationDataRaw,
			(acc, current, key) => {
				acc[key] = parseAnimation(current, `animation-${key}-${props._id}`);
				return acc;
			},
			{} as ParsedAnimationDataRecord
		);

		this.isEmbed =
			location.client.isEmbedDevPreview ||
			(window.parent !== window && !this.isEditableMode && !location.client.isPreview);

		const defaultTrigger = [
			ANIMATION_TRIGGER.ON_LOAD,
			ANIMATION_TRIGGER.ON_CARD_EXPOSE_START,
			ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE,
			ANIMATION_TRIGGER.ON_VIEWPORT,
			ANIMATION_TRIGGER.ON_HOVER,
			ANIMATION_TRIGGER.ON_CLICK,
		].filter(trigger => !!_.find(animationData, { trigger }))[0];

		const currentAnimation = defaultTrigger
			? _.find(animationData, { trigger: defaultTrigger })?.id ?? ''
			: Object.keys(animationData || {})[0];

		this.state = {
			currentAnimation: this.isEditableMode ? '' : currentAnimation,
			animationDataRaw,
			animationData,
			animationState: _.reduce(
				animationData,
				(acc, data, key) => {
					acc[key] = data && !this.isEditableMode ? ANIMATION_STATE.NOT_STARTED : ANIMATION_STATE.NONE;
					return acc;
				},
				{}
			),
		};
	}

	componentDidMount() {
		if (!this.isEditableMode && this.animationData) {
			_.forEach(this.animationData, (animationData, triggerId) => {
				if (animationData === null) {
					return;
				}

				const { trigger } = animationData;
				const isCustomTrigger = !_.values(ANIMATION_TRIGGER).some(t => t === animationData.trigger);

				if (trigger) {
					if (trigger === ANIMATION_TRIGGER.ON_LOAD) {
						this.startAnimation(triggerId);
					}
					if (trigger === ANIMATION_TRIGGER.ON_VIEWPORT) {
						if (this.containerRef.current) {
							// listen
							this.scrollListeners.add();

							// handle case when initially "in view" (details in 890aab3b)
							this.onWindowScroll();
						} else {
							const componentType = (this.props as any).type;
							throw new Error(
								`withAnimation HOC — wrapped ${componentType} reference error.
								${componentType} should attach 'props.containerRef' to its root HTMLElement`
							);
						}
					}
					if (
						isCustomTrigger ||
						trigger === ANIMATION_TRIGGER.ON_CARD_EXPOSE_START ||
						trigger === ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE
					) {
						if (trigger === triggerId) return;

						window.addEventListener(trigger, () => this.startAnimation(triggerId));
					}
				}
			});
		}

		if (this.animationData) {
			AnimationEvEm.addListener('playback', this.animationEvEmPlaybackListener);
		}

		// receiver message from Editor (run animation preview)
		window.addEventListener('message', this.onWindowMessage);
	}

	componentWillUnmount() {
		_.forEach(this.delayedEventTimeouts, timeouts => {
			_.forEach(timeouts, timeoutID => rafTicker.clearTimeout(timeoutID));
		});

		this.scrollListeners.remove();
		window.removeEventListener('message', this.onWindowMessage);
		AnimationEvEm.removeListener('playback', this.animationEvEmPlaybackListener);

		_.forEach(this.animationData, animationData => {
			if (_.get(animationData, 'trigger')) {
				const trigger = animationData?.trigger;

				window.removeEventListener<any>(trigger, this.startAnimation);
			}
		});
	}

	get animationData() {
		return this.state.animationData;
	}

	get isEditableMode() {
		return isEditableMode(this.props);
	}

	get scrollListeners() {
		const events = ['scroll', 'pageScroll'];
		return {
			add: () => events.forEach(ev => window.addEventListener(ev, this.onWindowScroll)),
			remove: () => events.forEach(ev => window.removeEventListener(ev, this.onWindowScroll)),
		};
	}

	animationEvEmPlaybackListener = (event: AnimationPlaybackEvent) => {
		Object.values(this.animationData ?? {}).forEach(animation => {
			if (!animation) return;
			const { trigger, id } = animation;
			const startAnimation = () => this.startAnimation(id);
			switch (event.data.action) {
				case 'onMultipleAnswerSubmit':
				case 'onFormSubmit':
					if (trigger === ANIMATION_TRIGGER.ON_SUBMIT) startAnimation();
					break;
				case 'onSlideChangeStart': {
					const { _id } = event.data;

					if (trigger === ANIMATION_TRIGGER.ON_VIEWPORT && _id === this.props._id) startAnimation();
					break;
				}
				case 'onAnswer':
				case 'onMultipleAnswerSelect': {
					const { isCorrect = false, answerNodeId = null } = event.data;
					const triggerConditions = {
						[ANIMATION_TRIGGER.ON_CARD_ANSWER]: true,
						[ANIMATION_TRIGGER.ON_CARD_ANSWER_CORRECT]: isCorrect,
						[ANIMATION_TRIGGER.ON_CARD_ANSWER_INCORRECT]: !isCorrect,
					};

					if (triggerConditions[trigger]) {
						const currentElement = this.containerRef.current ?? null;
						const selectedAnswer = answerNodeId ? document.getElementById(answerNodeId) : null;
						const isSelectedAnswerChild = selectedAnswer?.contains(currentElement);
						const isAnswerChild = currentElement?.closest(`[data-bb="${COMPONENT_TYPE.ANSWER}"]`);

						if (isSelectedAnswerChild || !isAnswerChild) {
							startAnimation();
						}
					}
					break;
				}
				default:
					break;
			}
		});
	};

	getCurrentAnimationStyles = (): { [key: string]: string } | undefined => {
		const { currentAnimation, animationState } = this.state;
		const animationData = this.animationData[currentAnimation];
		let style: { [key: string]: string } | undefined;

		if (!animationData) {
			return undefined;
		}

		const { name, reverse, duration, loop, sortedKeyframes } = animationData;

		switch (animationState[currentAnimation]) {
			case ANIMATION_STATE.NOT_STARTED:
				style = _.first(sortedKeyframes)?.[1];
				break;
			case ANIMATION_STATE.PLAYING: // const animationData = animationData;
				style = {
					..._.first(sortedKeyframes)?.[1],
					animationName: name,
					animationDuration: `${parseFloat(`${duration}`) * (reverse ? 0.5 : 1)}ms`,
					animationTimingFunction: 'linear', // it should always be linear
					animationFillMode: 'both',
					animationIterationCount: `${loop ? Number.MAX_VALUE : reverse ? 2 : 1}`,
					animationDirection: reverse ? 'alternate' : 'normal',
				};
				break;
			case ANIMATION_STATE.COMPLETE:
				if (reverse) {
					style = _.first(sortedKeyframes)?.[1];
				} else {
					style = _.last(sortedKeyframes)?.[1];
				}
				break;
			case ANIMATION_STATE.NONE:
				style = {
					transform: '',
					opacity: '',
				};
				break;
			default:
				break;
		}

		if (style) {
			style = Object.entries(style).reduce((acc, [key, value]) => {
				acc[camelCaseStr(key)] = value;
				return acc;
			}, {});
		}

		return style;
	};

	getEventListeners = () => {
		const listeners: Partial<BBCommonProps['eventListeners']> = {};

		_.forEach(this.animationData, animationData => {
			if (animationData && !this.isEditableMode) {
				if (animationData.trigger === ANIMATION_TRIGGER.ON_CLICK) {
					listeners.onClick = this.onClick;
				}
				if (animationData.trigger === ANIMATION_TRIGGER.ON_HOVER) {
					listeners.onMouseEnter = this.onMouseEnter;
					listeners.onMouseLeave = this.onMouseLeave;
				}
			}
		});

		return listeners;
	};

	startAnimation = async (trigger: string, p = {}) => {
		const animationState = this.state.animationState[trigger];
		const animationTriggerData = this.animationData[trigger];

		if (!animationTriggerData) {
			return;
		}

		const triggerName = animationTriggerData.trigger;
		const { ON_CARD_EXPOSE_START, ON_CARD_EXPOSE_COMPLETE } = ANIMATION_TRIGGER;

		// Prevent call of expose animation triggers on card expose OUT
		if (
			(triggerName === ON_CARD_EXPOSE_START || triggerName === ON_CARD_EXPOSE_COMPLETE) &&
			(animationState === 'COMPLETE' || animationState === 'PLAYING')
		) {
			return;
		}

		const defaultParams: { mode: 'normal' | 'preview' } = { mode: 'normal' };
		const params = { ...defaultParams, ...p };

		if (!this.delayedEventTimeouts[trigger]) this.delayedEventTimeouts[trigger] = [];
		_.forEach(this.delayedEventTimeouts[trigger], timeoutID => rafTicker.clearTimeout(timeoutID));

		if (this.state.animationState[trigger] === ANIMATION_STATE.PLAYING) {
			await new Promise<void>(resolve =>
				this.setState(
					state => ({
						animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.NOT_STARTED },
					}),
					resolve
				)
			);
		}

		this.setState({ currentAnimation: trigger }, () => {
			const delayTimeoutId = rafTicker.setTimeout(() => {
				_.forEach(animationTriggerData.events, event => {
					const timeoutID = rafTicker.setTimeout(() => {
						window.dispatchEvent(new Event(event.eventId));
					}, event.delay);
					this.delayedEventTimeouts[trigger].push(timeoutID);
				});

				this.setState(state => ({
					animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.PLAYING },
				}));

				this.delayedEventTimeouts[trigger].push(
					rafTicker.setTimeout(() => {
						const targetState =
							params.mode === 'normal'
								? animationTriggerData.loop
									? ANIMATION_STATE.PLAYING
									: ANIMATION_STATE.COMPLETE
								: ANIMATION_STATE.NONE;

						this.setState(
							state => ({
								animationState: { ...state.animationState, [trigger]: targetState },
							}),
							() => {
								window.parent.postMessage(
									{
										sender: ANIMATION_HOC_SENDER_ID,
										type: 'complete',
										trigger,
										elementId: this.props._id,
									} as AnimationSettingsEvent,
									'*'
								);
							}
						);
					}, animationTriggerData.duration)
				);
			}, animationTriggerData.delay);

			this.delayedEventTimeouts[trigger].push(delayTimeoutId);
		});
	};

	stopAnimation = (trigger: string) => {
		_.forEach(this.delayedEventTimeouts, timeouts => {
			_.forEach(timeouts, timeoutID => rafTicker.clearTimeout(timeoutID));
		});

		this.setState(state => ({
			animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.NONE },
		}));
	};

	onWindowMessage = ({ data: e }: { data: AnimationSettingsEvent }) => {
		if (e.sender === ANIMATION_SETTINGS_SENDER_ID && e.elementId === this.props._id) {
			switch (e.type) {
				case 'start':
					this.startAnimation(e.trigger, { mode: 'preview' });
					break;

				case 'stop':
					this.stopAnimation(e.trigger);
					break;

				default:
					break;
			}
		}
	};

	onMouseEnter: MouseEventHandler = event => {
		_.invoke(this.props, 'eventListeners.onMouseEnter', event);
		_.invoke(this.props, 'uiConfig.nodeProps.onMouseEnter', event);

		const animation = _.find(this.animationData, {
			trigger: ANIMATION_TRIGGER.ON_HOVER,
		});

		if (animation) {
			this.startAnimation(animation.id);
		}
	};

	onMouseLeave: MouseEventHandler = event => {
		_.invoke(this.props, 'eventListeners.onMouseLeave', event);
		_.invoke(this.props, 'uiConfig.nodeProps.onMouseLeave', event);

		const animation = _.find(this.animationData, {
			trigger: ANIMATION_TRIGGER.ON_HOVER,
		});

		if (animation) {
			this.stopAnimation(animation.id);
		}
	};

	onClick: MouseEventHandler = event => {
		_.invoke(this.props, 'eventListeners.onClick', event);
		_.invoke(this.props, 'uiConfig.nodeProps.onClick', event);

		const animation = _.find(this.animationData, {
			trigger: ANIMATION_TRIGGER.ON_CLICK,
		});

		if (animation) {
			this.startAnimation(animation.id);
		}
	};

	onWindowScroll = (e?: Event) => {
		const container = this.containerRef.current;

		if (container) {
			const vpH = this.isEmbed ? _.get(e, 'detail.viewport.h', window.innerHeight) : window.innerHeight;
			const bBox = container.getBoundingClientRect();
			const pagePosTop = _.get(e, 'detail.containerPosTop', 0);
			const controlPoint = pagePosTop + bBox.y + bBox.height;

			if (controlPoint >= 0 && controlPoint <= vpH) {
				const animation = _.find(this.animationData, { trigger: ANIMATION_TRIGGER.ON_VIEWPORT });

				if (animation) this.startAnimation(animation.id);

				this.scrollListeners.remove();
			}
		}
	};

	render() {
		const { currentAnimation } = this.state;
		const keyframes = _.get(this.animationData[currentAnimation], 'style');
		const style = this.getCurrentAnimationStyles();
		const eventListeners = this.getEventListeners();
		const { Component, ...props } = this.props;
		const bBProps = produce(
			{
				uiConfig: this.props.uiConfig,
				eventListeners: { ...this.props.eventListeners, ...eventListeners } as BBCommonProps['eventListeners'],
				isEmbed: this.isEmbed,
			},
			draft => {
				_.set(draft, 'uiConfig.nodeProps.style', _.merge({}, _.get(draft, 'uiConfig.nodeProps.style'), style));
				_.set(draft, 'uiConfig.nodeProps.onClick', eventListeners.onClick);
				_.set(draft, 'uiConfig.nodeProps.onMouseEnter', eventListeners.onMouseEnter);
			}
		);

		return (
			<>
				{keyframes && <style>{keyframes}</style>}
				<Component {...props} {...bBProps} containerRef={this.containerRef as MutableRefObject<HTMLElement>} />
			</>
		);
	}
}

function camelCaseStr(str: string) {
	if (str.includes('-')) {
		const arr = str.split('-');
		const capital = arr.map((item, index) =>
			index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()
		);

		return capital.join('');
	}

	return str;
}

/**
 * HOC for animation functionality on BuildingBlocks
 * Animation data is parsed to regular css-animation keyframes,
 * which are appended to <style> next-to Component's resulting element. When animation is triggered, this HOC changes
 * props.uiConfig.nodeProps.style to start normal css-animation on Component's element.
 * For more details on animation concept see ./animation.js - parseAnimation description
 * Assumes Component attaches ref={props.containerRef} to its root HTMLElement
 */
function withAnimation<P>(Component: ComponentType<P>) {
	const HOC: React.FC<Omit<WithAnimationProps, 'Component' | keyof WithAnimationProvidedProps>> = props => (
		<WithAnimation {...props} Component={Component as ComponentType<BBCommonProps>}>
			{props.children}
		</WithAnimation>
	);

	HOC.displayName = `withAnimation(${Component.displayName || Component.name})`;

	return HOC;
}

export { withAnimation };
