import { COMPONENT_TYPE as TYPE } from 'common/constants';
import { TEXT } from 'client/components/common/BuildingBlocks/Text';
import { IMG } from 'client/components/common/BuildingBlocks/Img';
import { ANSWER } from 'client/components/common/BuildingBlocks/Answer';
import { CARD } from 'client/components/common/BuildingBlocks/Card';
import { FLOAT_ABOVE } from 'client/components/common/BuildingBlocks/FloatAbove';
import { FLOAT_BELOW } from 'client/components/common/BuildingBlocks/FloatBelow';
import { CONTENT } from 'client/components/common/BuildingBlocks/Content';
import { BUTTON } from 'client/components/common/BuildingBlocks/Button';
import { BUTTON_SUBMIT } from 'client/components/common/BuildingBlocks/Button/ButtonSubmit';
import { GOOGLE_LOGIN } from 'client/components/common/BuildingBlocks/Login/GoogleLogin';
import { SMS_LOGIN } from 'client/components/common/BuildingBlocks/Login/SMSLogin';
import { BOX } from 'client/components/common/BuildingBlocks/Box';
import { SHAPE } from 'client/components/common/BuildingBlocks/Shape';
import { EMBED } from 'client/components/common/BuildingBlocks/Embed';
import { TEXT_FIELD } from 'client/components/common/BuildingBlocks/Fields/TextField';
import { TEXT_AREA_FIELD } from 'client/components/common/BuildingBlocks/Fields/TextAreaField';
import { SHARE } from 'client/components/common/BuildingBlocks/Share';
import { RESULT_TEXT } from 'client/components/common/BuildingBlocks/Answer/ResultText';
import { RESULT_SHAPE } from 'client/components/common/BuildingBlocks/Answer/ResultShape';
import { CHECKBOX } from 'client/components/common/BuildingBlocks/Fields/Checkbox';
import { SWIPE } from 'client/components/common/BuildingBlocks/Swipe';
import { VIDEO } from 'client/components/common/BuildingBlocks/Video';
import { TIMER } from 'client/components/common/BuildingBlocks/Timer';
import { ANSWER_PROGRESS } from 'client/components/common/BuildingBlocks/AnswerProgress';
import { LOTTIE } from 'client/components/common/BuildingBlocks/Lottie';
import { SELECT } from 'client/components/common/BuildingBlocks/Fields/Select';
import { OVERLAY } from 'client/components/common/BuildingBlocks/Overlay';
import { SORTABLE_BOX } from 'client/components/common/BuildingBlocks/SortableBox';
import { COUNTER } from 'client/components/common/BuildingBlocks/Counter';
import { SLIDER } from 'client/components/common/BuildingBlocks/Slider';
import { SLIDER_ARROW } from 'client/components/common/BuildingBlocks/Slider/SliderArrow';
import { SLIDER_PAGINATION } from 'client/components/common/BuildingBlocks/Slider/SliderPagination';
import { SLIDER_SLIDES } from 'client/components/common/BuildingBlocks/Slider/SliderSlides';
import { SEARCH_FIELD } from 'client/components/common/BuildingBlocks/Fields/SearchField';
import { CMS_REPEATER } from 'client/components/common/BuildingBlocks/CmsRepeater';

export const COMPONENTS = {
	[TYPE.TEXT]: TEXT,
	[TYPE.IMG]: IMG,
	[TYPE.ANSWER]: ANSWER,
	[TYPE.CARD]: CARD,
	[TYPE.FLOAT_ABOVE]: FLOAT_ABOVE,
	[TYPE.FLOAT_BELOW]: FLOAT_BELOW,
	[TYPE.CONTENT]: CONTENT,
	[TYPE.BUTTON]: BUTTON,
	[TYPE.BUTTON_SUBMIT]: BUTTON_SUBMIT,
	[TYPE.GOOGLE_LOGIN]: GOOGLE_LOGIN,
	[TYPE.SMS_LOGIN]: SMS_LOGIN,
	[TYPE.BOX]: BOX,
	[TYPE.SHAPE]: SHAPE,
	[TYPE.EMBED]: EMBED,
	[TYPE.TEXT_FIELD]: TEXT_FIELD,
	[TYPE.TEXT_AREA_FIELD]: TEXT_AREA_FIELD,
	[TYPE.CHECKBOX]: CHECKBOX,
	[TYPE.SELECT]: SELECT,
	[TYPE.SHARE]: SHARE,
	[TYPE.RESULT_TEXT]: RESULT_TEXT,
	[TYPE.RESULT_SHAPE]: RESULT_SHAPE,
	[TYPE.SWIPE]: SWIPE,
	[TYPE.VIDEO]: VIDEO,
	[TYPE.TIMER]: TIMER,
	[TYPE.ANSWER_PROGRESS]: ANSWER_PROGRESS,
	[TYPE.LOTTIE]: LOTTIE,
	[TYPE.OVERLAY]: OVERLAY,
	[TYPE.SORTABLE_BOX]: SORTABLE_BOX,
	[TYPE.COUNTER]: COUNTER,
	[TYPE.SLIDER]: SLIDER,
	[TYPE.SLIDER_ARROW]: SLIDER_ARROW,
	[TYPE.SLIDER_SLIDES]: SLIDER_SLIDES,
	[TYPE.SLIDER_PAGINATION]: SLIDER_PAGINATION,
	[TYPE.SEARCH_FIELD]: SEARCH_FIELD,
	[TYPE.CMS_REPEATER]: CMS_REPEATER,
};
