import { PARAMS } from 'common/constants';

//
export const ANY = '*';
export const ROOT = '/';
export const NOT_FOUND = '/not-found';
export const SOLUTIONS_PAGE = `${ROOT}solutions`;
export const TEMPLATES_PAGE = `${ROOT}templates`;
export const PRICING_PAGE = `${ROOT}pricing`;
export const GET_STARTED_PAGE = `${ROOT}get-started`;
export const DASHBOARD_PAGE = `${ROOT}dashboard`;
export const SETTINGS_PAGE = `${ROOT}settings`;
export const PROFILE_PAGE = `${ROOT}profile`;
export const PROFILE_2FA_PAGE = `${PROFILE_PAGE}/2fa`;
export const REPORTS_PAGE = `${ROOT}reports`;
export const INVITE_ACCEPT_PAGE = `${ROOT}accept_invite`;
export const ACCOUNT_SETUP_PAGE = `${ROOT}setup_member`;
export const VERIFY_MEMBER_PAGE = `${ROOT}verify_member`;
export const ACCOUNT_SETUP_PLAN_PAGE = `${ACCOUNT_SETUP_PAGE}/plan`;
export const ACCOUNT_SETUP_WORKSPACE_PAGE = `${ACCOUNT_SETUP_PAGE}/workspace`;
export const ACCOUNT_SETUP_SETTNGS_PAGE = `${ACCOUNT_SETUP_PAGE}/settings`;
export const LEARN_PAGE = `${ROOT}learn`;
export const ICONS_PAGE = `${ROOT}icons`;

// login:
export const LOGIN_PAGE = `${ROOT}login`;
export const RESTORE_PASSWORD_PAGE = `${ROOT}restore_password`;
export const RESET_PASSWORD_PAGE = `${ROOT}reset_password`;
export const TWO_FA_ENFORCEMENT_PAGE = `${ROOT}2fa_enforcement`;

// videos:
export const VIDEOS_PAGE = `${ROOT}videos`;
export const VIDEOS_GALLERY_PAGE = `${VIDEOS_PAGE}/gallery`;
export const VIDEOS_STATISTICS_PAGE = `${VIDEOS_PAGE}/statistics`;
export const VIDEO_STATS_PAGE = `${VIDEOS_PAGE}/:${PARAMS.VIDEO_ID}/stats`;
export const VIDEO_DETAILS_PAGE = `${VIDEOS_PAGE}/:${PARAMS.VIDEO_ID}/details`;
export const VIDEO_ORIGINAL_FILE_PAGE = `${VIDEOS_PAGE}/:${PARAMS.VIDEO_ID}/original-file`;

// stories:
export const STORIES_PAGE = `${ROOT}stories`;
export const STORY_PAGE = `${STORIES_PAGE}/:${PARAMS.STORY_ID}`;
export const STORY_FLOW_PAGE = `${STORY_PAGE}/flow`;
export const STORY_EMBED_PAGE = `${STORY_PAGE}/embed`;
export const STORY_VERSIONS_PAGE = `${STORY_PAGE}/versions`;
export const STORY_CARD_PAGE = `${STORY_PAGE}/card/:${PARAMS.CARD_ID}`;
export const STORY_CARD_EDITOR_PAGE = `${STORY_CARD_PAGE}/editor`;

// reports:
export const REPORTS_STORY_PAGE = `${REPORTS_PAGE}/stories/:${PARAMS.STORY_ID}`;

// settings:
export const SETTINGS_GENERAL_PAGE = `${SETTINGS_PAGE}/general`;
export const SETTINGS_MEMBERS_PAGE = `${SETTINGS_PAGE}/members`;
export const SETTINGS_TEAMS_PAGE = `${SETTINGS_PAGE}/teams`;
export const SETTINGS_DOMAINS_PAGE = `${SETTINGS_PAGE}/domains`;
export const SETTINGS_TEAM_PAGE = `${SETTINGS_TEAMS_PAGE}/:${PARAMS.TEAM_ID}`;
export const SETTINGS_BILLING_PAGE = `${SETTINGS_PAGE}/billing`;
export const SETTINGS_BILLING_PLANS_PAGE = `${SETTINGS_BILLING_PAGE}/plans`;
export const SETTINGS_BILLING_DETAILS_PAGE = `${SETTINGS_BILLING_PAGE}/details`;
export const SETTINGS_BILLING_INVOICES_PAGE = `${SETTINGS_BILLING_PAGE}/invoices`;
export const SETTINGS_INTEGRATION_PAGE = `${SETTINGS_PAGE}/integration`;
export const SETTINGS_FONTS_PAGE = `${SETTINGS_PAGE}/fonts`;

// preview:
export const PREVIEW_ROOT = `${STORY_PAGE}/preview`;
export const STORY_PREVIEW_PAGE = `${PREVIEW_ROOT}/c/:${PARAMS.CARD_ID}`;

export const SUPERUSER_ROOT = '/superuser';
export const SUPERUSER_ORGANIZATION_PAGE = `${SUPERUSER_ROOT}/organization`;
export const SUPERUSER_STORY_COPY_PAGE = `${SUPERUSER_ROOT}/story-copy`;
export const SUPERUSER_STORY_PLAYGROUND_PAGE = `${SUPERUSER_ROOT}/playground`;

// oauth:
export const OAUTH = `${ROOT}oauth`;
export const OAUTH_LOGIN = `${OAUTH}/login`;
export const OAUTH_CONSENT = `${OAUTH}/consent`;
export const OAUTH_ERROR = `${OAUTH}/error`;
export const OAUTH_GOOGLE_CALLBACK = `${OAUTH}/google/callback`;
