import React from 'react';
import ReactDOM from 'react-dom';
import css from './Debugger.scss';

const RecursiveObjectViewer = ({ data }) => {
	// Recursive function to render the object or array
	const renderObject = obj => {
		if (typeof obj !== 'object' || obj === null) {
			// Render primitive values (string, number, etc.)
			return <span>{String(obj)}</span>;
		}

		return (
			<details open>
				<summary>{Array.isArray(obj) ? `Array [${obj.length}]` : 'Object'}</summary>
				<div>
					{Object.entries(obj).map(([key, value]) => (
						<div key={key}>
							<strong>{key}:</strong> {renderObject(value)}
						</div>
					))}
				</div>
			</details>
		);
	};

	return <div>{renderObject(data)}</div>;
};

const Debugger = () => {
	const [value, setValue] = React.useState<object | null>(null);
	const [side, setSide] = React.useState<'left' | 'right'>('left');

	React.useEffect(() => {
		window.__storyDebug = (_value: typeof value, _side?: typeof side) => {
			setValue(_value);
			if (_side) setSide(_side);
		};
	}, []);

	if (value === null) return null;

	return ReactDOM.createPortal(
		<div className={css.debugger} style={side === 'left' ? { left: 0 } : { right: 0 }}>
			<div className={css.content}>
				<RecursiveObjectViewer data={value} />
			</div>
			<div>
				<button type="button" onClick={() => setValue(null)}>
					hide
				</button>
				&nbsp;
				<button type="button" onClick={() => setSide(s => (s === 'left' ? 'right' : 'left'))}>
					align
				</button>
			</div>
		</div>,
		document.body
	);
};

export default Debugger;
